import React, { useCallback, useState, useRef, useEffect } from "react";
import moment from "moment";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";

import { api } from "../../api";
import FieldAndCheckboxCombo from "./FieldAndCheckboxCombo";
// import { color } from "d3";
// import { size } from "underscore";

const GOOGLE_MAPS_API_KEY = "AIzaSyAefTcc0g350knYKziO_d2l_3eJyEn5T18";
const libraries = ["places"];

const defaultCenter = {
  lat: 50.888951,
  lng: 4.449588,
};

const saveChangeButtonStyle = {
  position: "absolute",
  right: "1em",
  bottom: "-5.4em",
  borderTop: "none",
  borderRadius: "0 0px 5px 5px",
};

const LocationStep = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const defaultLocation = {
    lat: props.building.latitude || defaultCenter.lat,
    lng: props.building.longitude || defaultCenter.lng,
  };
  const [marker, setMarker] = useState(defaultLocation);
  const [position, setPosition] = useState(defaultLocation);
  const [changed, setChanged] = useState(false);
  const buildingTimers = props.building.timers || {};
  const [dateField, setDateField] = useState(buildingTimers.date || "");
  const [timeField, setTimeField] = useState(buildingTimers.time || "");
  const [dateTimeField, setDateTimeField] = useState(
    buildingTimers.datetime || ""
  );
  const [timerInterval, setTimerInterval] = useState(
    buildingTimers.interval || ""
  );
  const [errors, setErrors] = useState({ dateField: '', timeField: '', dateTimeField: '' });
  const [timerSource, setTimerSource] = useState(buildingTimers.source || "");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loaded ? setChanged(true) : setLoaded(true);
  }, [dateField, timeField, dateTimeField]);

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(11);
    setMarker({ lat: lat, lng: lng });
    setChanged(true);
  }, []);

  const onMapClick = useCallback(({ latLng }) => {
    setMarker({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
    setChanged(true);
  }, []);

  const validateAddress = (address) => {
    if (!address) {
      return false;
    }
    const val = address.trim();
    const addr = val.match(/(\d)+(\.(\d)+){1,2}/g);
    if (addr) {
      if (Array.isArray(addr)) {
        if (addr[0]) return true;
      }
    }
    return false;
  };

  const handleSave = () => {
    let changes = Object.assign(
      {},
      {
        latitude: marker.lat,
        longitude: marker.lng,
        timers: {
          interval: timerInterval,
          source: timerSource,
        },
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      }
    );
    if (dateField) changes.timers.date = dateField;
    if (timeField) changes.timers.time = timeField;
    if (dateTimeField) changes.timers.datetime = dateTimeField;
    if (changes.timers) {
      if (changes.timers.interval === undefined) changes.timers.interval = 1;
      if (changes.timers.source === undefined) changes.timers.source = "1.1.200";
    }
    if (
      changes.latitude === defaultCenter.lat &&
      changes.longitude === defaultCenter.lng
    ) {
      delete changes.latitude;
      delete changes.longitude;
    }
    Object.assign(props.building, changes);
    api("/building/update/" + props.building._id, {
      method: "POST",
      body: JSON.stringify(changes),
    }).then(setChanged(false));
  };

  const validateField = (field, value) => {
    const regex = /^([0-2]?[0-9]|3[01])\/([0-7])\/([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-4])$/;
    if (!regex.test(value)) {
      return `* Invalid format for ${field}. Expecting GroupAddress format e.g. 31/8/254`;
    }
    return '';
  };

  const handleDateFieldChange = (value) => {
    setDateField(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      dateField: validateField('dateField', value),
    }));
  };

  const handleTimeFieldChange = (value) => {
    setTimeField(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      timeField: validateField('timeField', value),
    }));
  };

  const handleDateTimeFieldChange = (value) => {
    setDateTimeField(value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      dateTimeField: validateField('dateTimeField', value),
    }));
  };

  const errorStyle = {
    color: "red",
    size: 6
  };

  return (
    <div className="ui form">
      {/* Location and google maps */}
      <div
        className="ui two column middle aligned very relaxed stackable grid"
        style={{ position: "relative" }}
      >
        {isLoaded ? (
          <div className="gmap column">
            Search and pin location on map
            <Search position={position} panTo={panTo} />
            <div className="mapControl">
              <GoogleMap
                mapContainerStyle={{ height: "100%", width: "100%" }}
                center={position}
                zoom={11}
                onClick={onMapClick}
                onLoad={onMapLoad}
              >
                <Marker position={marker} />
              </GoogleMap>
            </div>
          </div>
        ) : (
          "Loading Maps"
        )}
        <div className="ui vertical divider">Or</div>
        <div className="column">
          <div className="fields">
            <div className="eight wide latitude field">
              <label>
                Latitude
                <input
                  type="text"
                  value={marker.lat}
                  onChange={(e) => {
                    setPosition({
                      lat: Number(e.target.value),
                      lng: marker.lng,
                    });
                    setMarker({
                      lat: Number(e.target.value),
                      lng: marker.lng,
                    });
                    setChanged(true);
                  }}
                />
              </label>
            </div>
            <div className="eight wide longitude field">
              <label>
                Longitude
                <input
                  type="text"
                  value={marker.lng}
                  onChange={(e) => {
                    setPosition({
                      lng: Number(e.target.value),
                      lat: marker.lat,
                    });
                    setMarker({
                      lng: Number(e.target.value),
                      lat: marker.lat,
                    });
                    setChanged(true);
                  }}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="ui section divider"></div>
      {/* Building Timers */}
      <div className="fields">
        <div className="sixteen wide field">
          <p>Broadcast local date and time to this building:</p>
          <p>Enable timers by types and fill in destination group address</p>
          {errors.dateField && <div className="error" style={errorStyle} >{errors.dateField}</div>}
          {errors.timeField && <div className="error" style={errorStyle}>{errors.timeField}</div>}
          {errors.dateTimeField && <div className="error" style={errorStyle}>{errors.dateTimeField}</div>}
        </div>
      </div>

      <div className="ui segment fields">
        {/* Date Timer */}
        <FieldAndCheckboxCombo
          field={dateField}
          setField={handleDateFieldChange}
          label={
            <label>
              <b>Date Only</b> <br />
              <small>ex: {moment().format("DD-MM-YYYY")}</small>
            </label>
          }
        />

        {/* Time Timer */}
        <FieldAndCheckboxCombo
          field={timeField}
          setField={handleTimeFieldChange}
          label={
            <label>
              <b>Time Only</b> <br />
              <small>ex: {moment().format("HH:mm:ss")}</small>
            </label>
          }
        />

        {/* DateTime Timer */}
        <FieldAndCheckboxCombo
          field={dateTimeField}
          setField={handleDateTimeFieldChange}
          label={
            <label>
              <b>Date and Time</b> <br />
              <small>ex: {moment().format("DD-MM-YYYY HH:mm:ss")}</small>
            </label>
          }
        />
        
      </div>

      {/* Timer settings*/}
      <div className="four fields">
        <div className="field" />
        <div className="field" />
        <div className="field">
          <label>
            Timer Frequency
            <select
              className="ui dropdown"
              defaultValue={timerInterval}
              selected={timerInterval}
              onBlur={(e) => {
                setChanged(true);
                setTimerInterval(Number(e.target.value));
              }}
              onChange={(e) => {
                setChanged(true);
                setTimerInterval(Number(e.target.value));
              }}
            >
              <option value="1">Every minute</option>
              <option value="5">Every 5 minutes</option>
            </select>
          </label>
        </div>
        <div className="field">
          <label>
            Timer source address
            <input
              type="text"
              placeholder="1.1.200"
              defaultValue={timerSource}
              onChange={(e) => {
                setChanged(true);
                let val = e.target.value.trim();
                if (!val) val = "1.1.200";

                if (validateAddress(val)) {
                  window.$(e.target).closest(".field").removeClass("error");
                  setTimerSource(val);
                } else {
                  window.$(e.target).closest(".field").addClass("error");
                }
              }}
            />
          </label>
        </div>
      </div>
      {/* Save change, hidden when new building */}
      {props.building._id && changed ? (
        <div className="ui segment" style={saveChangeButtonStyle}>
          <button className="ui positive button" onClick={() => handleSave()}>
            Save Changes
          </button>
        </div>
      ) : undefined}
    </div>
  );
};

const Search = ({ position, panTo }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => position.lat, lng: () => position.lng },
      radius: 200000,
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="search">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Enter a location"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ description }) => (
                <ComboboxOption key={description} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};

export default LocationStep;
