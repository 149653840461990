const colors = [
  "#6ED0E0",
  "#7EB26D",
  "#EAB839",
  "#EF843C",
  "#E24D42",
  "#1F78C1",
  "#BA43A9",
  "#705DA0", //1
  "#508642",
  "#CCA300",
  "#447EBC",
  "#C15C17",
  "#890F02",
  "#0A437C",
  "#6D1F62",
  "#584477", //2
  "#B7DBAB",
  "#F4D598",
  "#70DBED",
  "#F9BA8F",
  "#F29191",
  "#82B5D8",
  "#E5A8E2",
  "#AEA2E0", //3
  "#629E51",
  "#E5AC0E",
  "#64B0C8",
  "#E0752D",
  "#BF1B00",
  "#0A50A1",
  "#962D82",
  "#614D93", //4
  "#9AC48A",
  "#F2C96D",
  "#65C5DB",
  "#F9934E",
  "#EA6460",
  "#5195CE",
  "#D683CE",
  "#806EB7", //5
  "#3F6833",
  "#967302",
  "#2F575E",
  "#99440A",
  "#58140C",
  "#052B51",
  "#511749",
  "#3F2B5B", //6
  "#E0F9D7",
  "#FCEACA",
  "#CFFAFF",
  "#F9E2D2",
  "#FCE2DE",
  "#BADFF4",
  "#F9D9F9",
  "#DEDAF7", //7
];

const lcdStyle = {
  borderRadius: 2,
  padding: "0.8em 0.1em 0.5em 0.4em",
  fontSize: "1.8em",
  textAlign: "right",
  background: "#303a3b linear-gradient(-80deg, #006190, #004F65)",
  color: "#fff",
  textShadow: "0 0 0.5em #00bcd4",
  boxShadow: "1px 0 0 0 #555 inset, 0 0 1em 0 rgba(0, 0, 0, 0.6) inset",
  fontFamily: "LCD",
  fontWeight: 400,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  minHeight: 43,
};

const historyColumns = [
  { headerName: "Time", field: "t", width: 120 },
  { headerName: "Source", field: "o", width: 80 },
  { headerName: "Command", field: "c", width: 60 },
  {
    headerName: "Value",
    field: "v",
    width: 120,
    cellRenderer: ({ data }) => {
      if (
        !data._dpt ||
        !data._dpt.formatter ||
        data.v === undefined ||
        data.v === null
      ) {
        return "";
      }
      let pretty = data._dpt.formatter(data.v);
      if (data._dpt.unit) {
        pretty += " " + data._dpt.unit;
      }
      return pretty;
    },
  },
];

// Data decoded with one of these "converters" needs to be put in the nonNumericGroups list when you do a time series query
// TODO: duplicated in BuildingDashboard.js
const nonNumericConverters = [
  "DPTBoolean",
  "DPT1BitControlled",
  "DPT3BitControlled",
  "DPTTime",
  "DPTDate",
  "DPTDateTime",
  "DPTRGB",
  "DPTString",
  "DPTSceneControl",
];

export { colors, lcdStyle, historyColumns, nonNumericConverters };
