import React, { useState } from "react";
import { Form, Radio } from "semantic-ui-react";

const DPTHVACMode = ({ value, onSend }) => {
  const [dptHvacMode, setDptHvacMode] = useState(value);

  const send = (val) => {
    if (onSend) {
      onSend(val);
      setDptHvacMode(val);
    }
  };
  return (
    <Form>
      <Form.Group inline>
        <Form.Field>
          <Radio
            name="value"
            label="Comfort"
            checked={dptHvacMode == 1 || dptHvacMode == "Comfort"}
            onChange={() => {
              send(1);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            name="value"
            label="Standby"
            checked={dptHvacMode == 2 || dptHvacMode == "Standby"}
            onChange={() => {
              send(2);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            name="value"
            label="Night"
            checked={dptHvacMode == 3 || dptHvacMode == "Night"}
            onChange={() => {
              send(3);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            name="value"
            label="Auto"
            checked={dptHvacMode == 4 || dptHvacMode == "Auto"}
            onChange={() => {
              send(4);
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default DPTHVACMode;
