import React, { createRef, useEffect, useState, useRef } from "react";

import _ from "underscore";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import MG from "metrics-graphics";

import "ag-grid-community/dist/styles/ag-grid.css";
import "../../styles/ag_grid.css";

import {
  colors,
  lcdStyle,
  historyColumns,
  nonNumericConverters,
} from "./buildingPanelConsts";
import { api } from "../../api";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import DPTComboBox from "../DPTComboBox/DPTComboBox";
import * as DPT from "../DPTComboBox/dpt";
import DPTControls from "./controls";

const BuildingGroupPanel = (props) => {
  const [editingMode, setEditingMode] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [showAs, setShowAs] = useState("");
  const [gridApi, setGridApi] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [btnShowChart, setBtnShowChart] = useState();
  const [btnShowTable, setBtnShowTable] = useState();
  const [historyChart, setHistoryChart] = useState();

  const didMountRef = useRef(false);

  useEffect(() => {
    refreshHistoryChart(props.group.id);
  }, [startTime, endTime, showAs]);

  useEffect(() => {
    setEditingMode(false);
    if (props.group !== undefined && props.group.id !== undefined) {

      // console.log('showAs ',showAs);
      if (showAs === "table") {
        //render grid
        try {
          gridApi.setRowData([]);
          // console.log(gridApi)
          // gridApi.sizeColumnsToFit(); //not needed as no data
        } catch (e) {}
      }
      refreshHistoryChart(props.group.id);
    }
  }, [props.group]);

  useEffect(() => {
    if (didMountRef.current) {
      if (showAs === "table") {
        //render grid
        try {
          gridApi.setRowData(historyData[0] || []);
          gridApi.sizeColumnsToFit();
        } catch (e) {}
      } else {
        //render chart
        const plotData =
          !historyData[0].length || isNaN(historyData[0][0].v)
            ? [[]]
            : historyData;
        try {
          MG.data_graphic({
            title: "",
            full_width: true,
            data: plotData,
            animate_on_load: false,
            transition_on_update: false,
            buffer: 0,
            top: 15,
            left: 35,
            target: historyChart,
            chart_type: plotData[0].length ? "line" : "missing-data",
            x_accessor: "time",
            y_accessor: "v",
            x_mouseover: (o) =>
              moment(o.time).format("DD-MM-YYYY HH:mm:ss") + " ",
            min_y_from_data: true,
            interpolate: "step-after",
            small_text: true,
            colors: colors,
            aggregate_rollover: true,
            show_tooltips: false,
          });
        } catch (e) {}
      }
    } else didMountRef.current = true;
  }, [historyData, showAs]);

  const refreshHistoryChart = (group) => {
    const payload = {
      groups: [group],
    };
    if (showAs === "table") payload.noAveraging = true;
    if (startTime) payload.start = startTime;
    if (endTime) payload.end = endTime;

    if (
      props.group._dpt &&
      nonNumericConverters.includes(props.group._dpt.converter)
    ) {
      payload.nonNumericGroups = [group];
    }

    const domNode = wrapper.current;

    window.$(domNode).find(".history .segment").addClass("loading");
    api("/building/ts/groups/" + props.params.buildingId, {
      method: "POST",
      body: JSON.stringify(payload),
    })
      .then((res) => {
        window.$(domNode).find(".history .segment").removeClass("loading");
        if (res.success) {
          // console.log(res.results);
          const data = res.results.map((dg) =>
            _.sortBy(
              dg.map((dgt) => {
                dgt.time = moment.utc(dgt.time).toDate();
                dgt.t = moment(dgt.time).format("DD-MM-YYYY HH:mm:ss");
                dgt._dpt = { formatter: props.group._dpt.formatter };
                return dgt;
              }),
              "time"
            )
          );
          setHistoryData(data);
        }
      })
      .catch(() => {
        window.$(domNode).find(".history .segment").removeClass("loading");
      });
  };

  const wrapper = createRef();

  if (props.group === undefined || props.group.id === undefined)
    return (
      <div className="ui segments" style={props.style}>
        <div className="ui bottom attached warning message">
          <Icon name="warning" />
          Please select a group
        </div>
      </div>
    );

  return (
    <div className="ui segments" style={props.style} ref={wrapper}>
      <div className="ui segment" style={{ minHeight: 46 }}>
        <span
          className="ui blue ribbon label"
          style={{
            position: "absolute",
            left: -15,
            top: "0.8em",
            backgroundColor: "#009FDA!important",
          }}
        >
          {props.group.id}
        </span>
        <b style={{ marginLeft: "5em", marginTop: "0.1em" }}>
          {props.group.name}
        </b>
        {props.group.alias ? (
          <span
            className="ui left pointing basic label"
            title="short name to appear on charts/widgets"
          >
            {props.group.alias}
          </span>
        ) : undefined}

        <button
          className="ui mini labeled icon button"
          style={{ float: "right" }}
          onClick={() => {
            setEditingMode(!editingMode);
          }}
        >
          <Icon name="write" />
          {editingMode ? "Close" : "Edit"}
        </button>
        {props.group._com.read === true ? (
          <button
            className="ui mini labeled icon button"
            style={{ float: "right" }}
            title="Send READ command"
            onClick={() => {
              // send read command
              api("/building/read/" + props.params.buildingId, {
                method: "POST",
                body: JSON.stringify({
                  id: props.group.id,
                }),
              }).catch((err) => {
                alert(err);
              });
            }}
          >
            <Icon name="upload" />
            Read
          </button>
        ) : undefined}
      </div>
      {editingMode ? (
        <div className="ui secondary groupEdit segment">
          <div className="ui form">
            <div className="field">
              <div className="fields">
                <div className="twelve wide field">
                  <label>
                    Name
                    <input
                      type="text"
                      name="name"
                      placeholder="Group Name .."
                      defaultValue={props.group.name}
                    />
                  </label>
                </div>
                <div className="four wide field">
                  <label>
                    Alias
                    <input
                      type="text"
                      name="alias"
                      placeholder="Alias .."
                      defaultValue={props.group.alias}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <DPTComboBox
            style={{ marginTop: "1em" }}
            defaultVal={props.group.dpt}
            sizeinbit={props.group._com.sizeinbit}
          />

          <div className="ui form" style={{ marginTop: "1em" }}>
            <div className="field">
              <button
                className="ui green labeled icon button"
                onClick={() => {
                  //Save Group
                  let group = {};
                  group.name = window.$('.groupEdit input[name="name"]').val();
                  group.alias = window
                    .$('.groupEdit input[name="alias"]')
                    .val();
                  if (group.alias === "") delete group.alias;
                  else group.alias = group.alias.trim();

                  group.dpt = window.$(".groupEdit select").val();
                  let dpt = DPT.getById(group.dpt);
                  if (dpt === undefined) dpt = DPT.getByName(group.dpt);
                  if (dpt !== undefined && group.name.length > 1) {
                    group.dpt = dpt.name;

                    // Check for changed fields
                    if (group.dpt === props.group.dpt) delete group.dpt;
                    group.name = group.name.trim();
                    if (group.name === props.group.name) delete group.name;
                    if (group.alias === props.group.alias) delete group.alias;

                    if (Object.keys(group).length === 0) {
                      setEditingMode(false);
                      return;
                    }

                    window
                      .$(wrapper.current)
                      .find(".ui.dimmer")
                      .addClass("active");

                    // submit changes
                    api("/building/group/" + props.params.buildingId, {
                      method: "POST",
                      body: JSON.stringify({
                        id: props.group.id,
                        update: group,
                      }),
                    })
                      .then(() => {
                        Object.assign(props.group, group);
                        props.group._dpt = dpt;
                        props.group._formatter = dpt.formatter;

                        if (props.onChanged) props.onChanged();

                        window
                          .$(wrapper.current)
                          .find(".ui.dimmer")
                          .removeClass("active");
                        setEditingMode(false);
                      })
                      .catch(() => {
                        alert("ERROR: Invalid Parameters");
                        window
                          .$(wrapper.current)
                          .find(".ui.dimmer")
                          .removeClass("active");
                      });
                  }
                }}
              >
                <Icon name="checkmark" />
                Save
              </button>
            </div>
          </div>
          <div className="ui inverted dimmer">
            <div className="ui text loader">Saving</div>
          </div>
        </div>
      ) : (
        <div
          className="ui secondary groupStatus segment"
          style={{ minHeight: "6em" }}
        >
          <div
            style={{
              position: "relative",
              margin: "0 -1em 1em 1em",
              width: "40%",
              float: "right",
              boxShadow: "0 0 0.8em -.35em #000",
            }}
          >
            <div style={lcdStyle}>
              {props.lastValue === undefined ? "_" : props.lastValue}
            </div>
            <span
              className="timestamp"
              style={{
                left: "0.5em",
                color: "#9DC4D2",
                position: "absolute",
                top: "0",
                fontSize: "11px",
                fontFamily: "LCD",
              }}
            >
              {props.lastValueTime === undefined ? " " : props.lastValueTime}
            </span>
            {props.group._com.group ? (
              <div
                className="flags"
                style={{
                  left: "0em",
                  color: "#9DC4D2",
                  position: "absolute",
                  bottom: 1,
                  fontSize: "11px",
                  fontFamily: "LCD",
                }}
              >
                {props.group._com.read === true ? (
                  <span className="flag">read</span>
                ) : undefined}
                {props.group._com.write === true ? (
                  <span className="flag">write</span>
                ) : undefined}
                {props.group._com.transmit === true ? (
                  <span className="flag">trans</span>
                ) : undefined}
                {props.group._com.CommunicationFlag === true ? (
                  <span className="flag">comm</span>
                ) : undefined}
              </div>
            ) : undefined}
            <div
              style={{
                position: "absolute",
                right: "0.3em",
                fontSize: "0.8em",
              }}
            >
              {props.group._dpt ? props.group._dpt.text : ""}
            </div>
          </div>
          <div
            style={{
              float: "right",
              width: "60%",
              height: "3.5em",
              padding: "1em",
              paddingRight: "1em",
              borderRight: "solid 1px gainsboro",
              textAlign: "center",
            }}
          >
            {props.group._com.write === true
              ? React.createElement(DPTControls(props.group._dpt).default, {
                  value: props.lastValue,
                  onSend: (val) => {
                    // submit changes
                    api("/building/send/" + props.params.buildingId, {
                      method: "POST",
                      body: JSON.stringify({
                        id: props.group.id,
                        dpt: props.group._dpt
                          ? props.group._dpt.name
                          : props.group.dpt,
                        value: val,
                      }),
                    }).catch((err) => {
                      alert(err);
                    });
                  },
                })
              : "not writable"}
          </div>
        </div>
      )}
      <div
        className="ui segment"
        style={{ paddingBottom: "2em", paddingLeft: "6em" }}
      >
        <span
          className="ui blue ribbon label"
          style={{
            position: "absolute",
            left: -15,
            top: "0.8em",
            backgroundColor: "#009FDA!important",
          }}
        >
          History
        </span>

        <div
          style={{
            float: "left",
            marginTop: "-0.3em",
            background: "transparent",
          }}
        >
          <div className="ui mini buttons">
            <button
              ref={(ref) => setBtnShowChart(ref)}
              className="ui active button"
              onClick={() => {
                if (showAs !== "chart") {
                  btnShowChart.classList.add("active");
                  btnShowTable.classList.remove("active");
                  setShowAs("chart");
                }
              }}
            >
              &nbsp;
              <Icon name="area chart" />
              Chart&nbsp;
            </button>
            <div className="or" />
            <button
              ref={(ref) => setBtnShowTable(ref)}
              className="ui button"
              onClick={() => {
                if (showAs !== "table") {
                  btnShowTable.classList.add("active");
                  btnShowChart.classList.remove("active");
                  setShowAs("table");
                }
              }}
            >
              {" "}
              &nbsp;
              <Icon name="table" />
              Table&nbsp;
            </button>
          </div>
        </div>
        <div
          className="ui label"
          style={{
            float: "left",
            marginTop: "-1.1em",
            background: "transparent",
          }}
        >
          <Icon name="calendar" />
          <div className="ui small input">
            <DateRangePicker
              options={{ opens: "left", locale: { format: "DD-MM-YYYY" } }}
              onApply={(start, end) => {
                setStartTime(start.toDate());
                setEndTime(end.toDate());
              }}
            />
          </div>
        </div>
      </div>
      <div className="ui history segments">
        <div
          className={`ui historyChart segment ${
            showAs === "table" ? "hidden" : ""
          }`}
        >
          <div
            id="historyChart"
            style={{ minHeight: 220 }}
            ref={(ref) => setHistoryChart(ref)}
          ></div>
        </div>
        <div
          style={{
            width: "100%",
            height: 400,
            padding: 0,
          }}
          className={`ui ag-grid orange segment history-chart ${
            showAs === "table" ? "" : "hidden"
          }`}
        >
          <AgGridReact
            onGridReady={(args) => {
              setGridApi(args.api);
              if(showAs === 'table'){
                args.api.sizeColumnsToFit();
              }
            }}
            gridOptions={{
              defaultColDef: { resizable: true },
              suppressMovableColumns: true,
            }}
            columnDefs={historyColumns}
            rowHeight="25"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      <div
        style={{
          "paddingLeft": "1em",
          "paddingRight": "1em",
          "paddingBottom": "1em",
        }}
      >
        {showAs === "table" && historyData[0].length === 100
          ? "There may be more data points. Please narrow your time selection."
          : ""}
      </div>
    </div>
  );
};

export default BuildingGroupPanel;
