import React, { useEffect, useState } from "react";
import moment from "moment";
import { Icon, Checkbox } from "semantic-ui-react";
import { compileExpression } from "filtrex";
import Modal from "react-modal";

import { api } from "../api";
import BuildingMenu from "../components/BuildingMenu";
import GroupComboBox from "../components/GroupComboBox";

const modalStyles = {
  content: {
    background: "transparent",
    border: "none",
  },
};

const BuildingAlerts = (props) => {
  const [alertRules, setAlertRules] = useState(undefined);
  const [closed, setClosed] = useState(false);
  const [groups, setGroups] = useState();
  const [error, setError] = useState();
  const [editingAlertRule, setEditingAlertRule] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    props.setBuildingId(props.match.params.buildingId);
    Modal.setAppElement("body");
    fetchBuildingGroups(() => {
      fetchBuildingAlerts(() => {});
    });
    return setClosed(true);
  }, []);

  const ButtonGroup = ({ element, style }) => {
    return (
      <button
        style={style}
        className="ui button "
        data-snippet={element.character}
        onClick={(e) => {
          handleQuickInsert(e);
        }}
      >
        {element.display}
      </button>
    );
  };

  const handleQuickInsert = (evt) => {
    if (evt.target.getAttribute("data-snippet")) {
      //insert at current curose position in text area
      var cursorPosStart = window
        .$("#editingRuleExpression")
        .prop("selectionStart");
      var cursorPosEnd = window
        .$("#editingRuleExpression")
        .prop("selectionEnd");
      var v = window.$("#editingRuleExpression").val();
      var textBefore = v.substring(0, cursorPosStart);
      var textAfter = v.substring(cursorPosEnd, v.length);
      window
        .$("#editingRuleExpression")
        .val(textBefore + evt.target.getAttribute("data-snippet") + textAfter);
      validateExpression(uglifyExpression(evt.target.value));
    }
  };

  const validateExpression = (expression) => {
    try {
      compileExpression(expression);
    } catch (e) {
      if (e.message) {
        setError(e.message);
        return false;
      }
    }
    setEditingAlertRule((prevState) => ({
      ...prevState,
      expression: expression,
    }));
    setError(undefined);
    return true;
  };

  const enableAlert = (alert, checked) => {
    api("/building/" + props.match.params.buildingId + "/alerts/" + alert._id, {
      method: "POST",
      body: JSON.stringify({
        enabled: checked,
      }),
    }).then((res) => {
      if (res.success === true) {
        setErrorMessage("");
      } else if (res.error) {
        setErrorMessage(res.error);
      }
    });
  };

  const deleteAlert = () => {
    api(
      "/building/" +
        props.match.params.buildingId +
        "/alerts/" +
        editingAlertRule._id,
      {
        method: "DELETE",
      }
    ).then(() => {
      fetchBuildingAlerts();
      setEditingAlertRule(undefined);
    });
  };

  const saveAlert = () => {
    api(
      "/building/" +
        props.match.params.buildingId +
        "/alerts" +
        (editingAlertRule._id ? "/" + editingAlertRule._id : ""),
      {
        method: editingAlertRule._id ? "POST" : "PUT",
        body: JSON.stringify(editingAlertRule),
      }
    ).then((res) => {
      if (res.success === true) {
        setErrorMessage("");
        fetchBuildingAlerts();
      } else if (res.error) {
        setErrorMessage(res.error);
      }
      setEditingAlertRule(undefined);
    });
  };

  const fetchBuildingGroups = (cb) => {
    api("/building/group/" + props.match.params.buildingId).then((res) => {
      if (closed) return;
      if (res.groups) {
        setGroups(res.groups);
      }
      cb();
    });
  };

  const fetchBuildingAlerts = (cb) => {
    api("/building/" + props.match.params.buildingId + "/alerts").then(
      (res) => {
        if (res.results) {
          setAlertRules(res.results);
        }
        if (cb) cb();
      }
    );
  };

  if (!alertRules) {
    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...props} />
        <div
          className="ui loading segment"
          style={{
            border: "none",
            padding: "10em",
            width: "100%",
            height: "calc(100% - 100px)",
            margin: "0 auto",
          }}
        ></div>
      </div>
    );
  } else {
    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...props} />
        <div
          className="_page"
          style={{
            border: "none",
            padding: "1em",
            maxWidth: 1200,
            margin: "0 auto",
          }}
        >
          {errorMessage ? (
            <div className="ui negative message">{errorMessage}</div>
          ) : null}
          <table className="ui single line selectable celled table">
            <thead>
              <tr>
                <th width={50} style={{ textAlign: "center" }}>
                  <Icon name="power" />
                </th>
                <th width="99%">Name</th>
                <th>Debounce</th>
                <th>
                  <button
                    className="ui primary labeled icon button"
                    onClick={() => {
                      setEditingAlertRule({});
                      setError(undefined);
                    }}
                  >
                    <Icon name="plus" />
                    Alert Rule
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {alertRules.map((alert) => (
                <tr key={alert._id}>
                  <td>
                    <Checkbox
                      toggle
                      defaultChecked={alert.enabled}
                      onChange={(e, { checked }) => {
                        enableAlert(alert, checked);
                      }}
                    />
                  </td>
                  <td>{alert.name}</td>
                  <td>
                    {alert.debounce == undefined ? (
                      <i style={{ opacity: 0.4 }}>5 min</i>
                    ) : (
                      moment.duration(alert.debounce, "seconds").humanize()
                    )}
                  </td>
                  <td>
                    <button
                      className="ui button"
                      onClick={() => {
                        setEditingAlertRule(Object.assign({}, alert));
                      }}
                    >
                      <Icon name="write" />
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
              {alertRules.length == 0 ? (
                <tr key="empty0">
                  <td colSpan={4}>No alert rule. </td>
                </tr>
              ) : undefined}
            </tbody>
          </table>
        </div>
        <Modal isOpen={editingAlertRule !== undefined} style={modalStyles}>
          {editingAlertRule ? (
            <div className="ui workspace modal active" style={{ top: 50 }}>
              <div className="header">
                <div className="ui mini labeled input">
                  <div className="ui label">Alert Rule: </div>
                  <input
                    type="text"
                    placeholder="name"
                    autoComplete="off"
                    style={{ width: "30em" }}
                    defaultValue={editingAlertRule.name}
                    onChange={(event) => {
                      setEditingAlertRule((prevState) => ({
                        ...prevState,
                        name: event.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="alertRule content">
                <div className="ui form">
                  <div className="field">
                    <label>
                      Expression:
                      <textarea
                        id="editingRuleExpression"
                        rows="2"
                        defaultValue={prettifyExpression(
                          editingAlertRule.expression
                        )}
                        autoComplete="off"
                        style={{ background: "#f1f1f1" }}
                        onChange={(e) => {
                          validateExpression(uglifyExpression(e.target.value));
                        }}
                      ></textarea>
                    </label>
                  </div>
                </div>
                {error !== undefined ? (
                  <div className="ui negative message">
                    <pre>{error}</pre>
                  </div>
                ) : undefined}
                <div className="">
                  <div style={{ margin: "1em" }}>
                    <div className="ui icon buttons">
                      {[
                        { character: " == ", display: <>&#61;</> },
                        { character: " < ", display: <>&lt;</> },
                        { character: " <= ", display: <>&lt;=</> },
                        { character: " > ", display: <>&gt;</> },
                        { character: " >= ", display: <>&gt;=</> },
                      ].map((element) => (
                        <ButtonGroup
                          key={element.character}
                          element={element}
                        />
                      ))}
                    </div>
                    <div className="ui icon buttons" style={{ marginLeft: 15 }}>
                      {[
                        { character: " + ", display: <>+</> },
                        { character: " - ", display: <>-</> },
                        { character: " * ", display: <>x</> },
                        { character: " / ", display: <>&divide;</> },
                      ].map((element) => (
                        <ButtonGroup
                          key={element.character}
                          element={element}
                        />
                      ))}
                    </div>
                    <div className="ui icon buttons" style={{ marginLeft: 15 }}>
                      {[
                        { character: " or ", display: <>or</> },
                        { character: " and ", display: <>and</> },
                        { character: " not ", display: <>not</> },
                        { character: " in ", display: <>in</> },
                        { character: " ( x ? y : z) ", display: <>iff</> },
                      ].map((element) => (
                        <ButtonGroup
                          key={element.character}
                          element={element}
                        />
                      ))}
                      <br />
                    </div>
                  </div>
                  <div style={{ margin: "1em" }}>
                    <div className="ui icon buttons">
                      {[
                        { character: " abs() ", display: <>abs</> },
                        { character: " max() ", display: <>max</> },
                        { character: " min() ", display: <>min</> },
                        { character: " floor() ", display: <>floor</> },
                        { character: " ceil() ", display: <>ceil</> },
                      ].map((element) => (
                        <ButtonGroup
                          key={element.character}
                          element={element}
                          style={{ fontSize: 12 }}
                        />
                      ))}
                    </div>
                    <div className="ui icon buttons" style={{ marginLeft: 15 }}>
                      {[
                        { character: " log() ", display: <>log</> },
                        { character: " random() ", display: <>rand</> },
                        { character: " sqrt() ", display: <>sqrt</> },
                        { character: " round() ", display: <>0.</> },
                      ].map((element) => (
                        <ButtonGroup
                          key={element.character}
                          element={element}
                          style={{ fontSize: 11 }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="content" style={{ paddingLeft: "1em" }}>
                  <GroupComboBox
                    groups={groups}
                    buttonStyle={{
                      background: "white",
                      color: "#555",
                      width: "5em",
                      boxShadow: "0 0 0 1px #C1C1C1 inset",
                      height: "2.3em",
                    }}
                    onSelect={(group_id) => {
                      //insert group id at current curose position in text area
                      var cursorPosStart = window
                        .$("#editingRuleExpression")
                        .prop("selectionStart");
                      var cursorPosEnd = window
                        .$("#editingRuleExpression")
                        .prop("selectionEnd");
                      var v = window.$("#editingRuleExpression").val();
                      var textBefore = v.substring(0, cursorPosStart);
                      var textAfter = v.substring(cursorPosEnd, v.length);
                      window
                        .$("#editingRuleExpression")
                        .val(textBefore + group_id + textAfter);
                    }}
                  />
                </div>
                <div className="ui section divider"></div>
                <div className="content">
                  <div className="ui form">
                    <div className="inline field">
                      <label>
                        Alert Debounce Frequency:
                        <select
                          className="ui dropdown"
                          defaultValue={editingAlertRule.debounce}
                          onChange={(e) => {
                            setEditingAlertRule((prevState) => ({
                              ...prevState,
                              debounce: e.target.value,
                            }));
                          }}
                          onBlur={(e) => {
                            setEditingAlertRule((prevState) => ({
                              ...prevState,
                              debounce: e.target.value,
                            }));
                          }}
                        >
                          <option value="300">Once every 5 minutes</option>
                          <option value="3600">Once every 1 hour</option>
                          <option value="86400">Once every 1 day</option>
                        </select>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="actions">
                {/* Delete Action */}
                {editingAlertRule._id !== undefined ? (
                  <button
                    className="ui red left floated labeled icon button"
                    onClick={() => {
                      //delete alert
                      deleteAlert();
                    }}
                  >
                    <Icon name="trash alternate outline" /> Delete Rule
                  </button>
                ) : undefined}
                {/* Save Action */}
                <button
                  className={
                    editingAlertRule.expression && editingAlertRule.name
                      ? "ui positive labeled icon button"
                      : "ui grey disabled labeled icon button"
                  }
                  onClick={() => {
                    //Save alert
                    //console.log("NEW:", editingAlertRule);
                    saveAlert();
                  }}
                >
                  <Icon name="checkmark" /> Save Changes
                </button>
                {/* Close Window Action */}
                <button
                  className="ui grey button"
                  onClick={() => {
                    setEditingAlertRule(undefined);
                  }}
                >
                  Close Window
                </button>
              </div>
            </div>
          ) : undefined}
        </Modal>
      </div>
    );
  }
};

const prettifyExpression = (expression) => {
  if (expression == undefined) return "";
  return expression.replace(/Group(\d)+(_(\d)+){1,2}/g, function ($0) {
    return $0.replace("Group", "").replace(/_/g, "/");
  });
};

const uglifyExpression = (expression) => {
  if (expression == undefined) return "";
  return expression.replace(/(\d)+(\/(\d)+){1,2}/g, function ($0) {
    return "Group" + $0.replace(/\//g, "_");
  });
};

export default BuildingAlerts;
