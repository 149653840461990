import React, { useState } from "react";
import { Icon, Progress } from "semantic-ui-react";
import { api } from "../../api";

const DeleteBuilding = ({
  buildingName,
  buildingID,
  routerHistory,
  setBuildingId,
}) => {
  const [deleteButtonDisabled, disableDeleteButton] = useState(true);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  return (
    <>
      <h3 className="ui top attached header">
        <Icon name="trash" />
        <div className="content">
          Delete Building
          <span className="sub header">
            Permanently delete this building and all its data from KNXMonitor.
          </span>
        </div>
      </h3>
      <div className="ui attached segment">
        <div className="ui form">
          <div className="fields">
            <div className="eight wide field">
              {deleteInProgress ? (
                <p>Deleting building, please wait ...</p>
              ) : (
                <label>
                  This will delete all of the data on KNXMonitor relating to this
                  building. You cannot undo this step. Type the name of the
                  building to confirm you wish to delete it.
                  <input
                    type="text"
                    placeholder=""
                    onChange={(e) => {
                      e.target.value === buildingName
                        ? disableDeleteButton(false)
                        : disableDeleteButton(true);
                    }}
                  />
                </label>
              ) }
              <button
                className="ui negative button"
                disabled={deleteButtonDisabled}
                onClick={() => {
                  disableDeleteButton(true);
                  setDeleteInProgress(true);
                  api("/building/delete/" + buildingID, {
                    method: "POST",
                  }).then(() => {
                    setBuildingId(undefined);
                    routerHistory.push("/buildings");
                  });
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteBuilding;
