import React from "react";
import { Link } from "react-router-dom";
import _ from "underscore";
import moment from "moment";
import { Icon } from "semantic-ui-react";

import * as d3 from "d3";
import MG from "metrics-graphics";
import "metrics-graphics/dist/metricsgraphics.css";

import { api } from "../api";

class Buildings extends React.Component {
  constructor() {
    super();
    this.state = {
      buildings: undefined,
    };
  }

  componentDidMount() {
    api("/buildings").then((res) => {
      if (this.closed === true) return;
      this.setState(
        { buildings: res.sort((a, b) => a._id.localeCompare(b._id)) },
        () => {
          this.progressBuildingConnectonHistory();
        }
      );
    });
  }
  componentWillUnmount() {
    this.closed = true;
  }

  progressBuildingConnectonHistory() {
    var nextBuilding = _.find(this.state.buildings, (w) => {
      return w.enabled && !w.connectionHistory;
    });
    if (nextBuilding) {
      nextBuilding.connectionHistory = [];
      let self = this;
      api("/building/connectionHistory/" + nextBuilding._id).then((result) => {
        if (result.success && self.closed !== true) {
          if (result.result) {
            var dataPoints = result.result;
            nextBuilding.connectionHistory = dataPoints;
            self.progressBuildingConnectonHistory();
          }
          if (result.stats) {
            let eleId = "BuildingStatsChart" + nextBuilding._id;
            if (self[eleId]) {
              let data = result.stats.map((p) => ({
                t: moment.utc(p["1"]).toDate(),
                v: p["0"],
              }));
              if (data && data.length > 0)
                MG.data_graphic({
                  title: "",
                  data: data,
                  height: 200,
                  width: 300,
                  left: -10,
                  right: 1,
                  target: self[eleId],
                  x_accessor: "t",
                  y_accessor: "v",
                  xax_count: 5,
                  show_secondary_x_label: false,
                  // area: false,
                  small_text: true,
                  color: "#888888",
                  show_tooltips: false,
                  mouseover: function (d) {
                    d3.select("." + eleId + " svg .mg-active-datapoint")
                      .attr("transform", "translate(285,68.75)")
                      .text(d.v + " messages per min");
                  },
                });
            }
          }
        }
      });
    }
  }

  render() {
    let buildingCards = undefined;
    if (this.state.buildings) {
      var owners = _.uniq(this.state.buildings, (b) => b.owner);
      buildingCards = (
        <div className="ui cards">
          {this.state.buildings.map((b) => (
            <div className="building card" key={b._id}>
              <div
                className="content"
                style={{ minHeight: 172, overflow: "hidden" }}
              >
                {b.enabled ? (
                  <i
                    className={
                      "right floated icon " +
                      (b.logging_status === "CONNECTED"
                        ? "green play"
                        : "orange unlink")
                    }
                  />
                ) : undefined}
                <div
                  className="header"
                  style={{ fontSize: "1em", opacity: 0.5 }}
                >
                  {owners.length > 1 ? b.owner : undefined}
                </div>
                {b.enabled ? (
                  <div
                    className={"statsChart BuildingStatsChart" + b._id}
                    ref={(ref) => (this["BuildingStatsChart" + b._id] = ref)}
                    style={{
                      marginLeft: "-1em",
                      marginRight: "-1em",
                    }}
                  ></div>
                ) : (
                  <div
                    className="center aligned content"
                    style={{ marginTop: "25%" }}
                  >
                    <h5 className="ui disabled sub header">Disabled</h5>
                  </div>
                )}
              </div>
              <Link
                className="ui bottom attached button"
                style={{ fontWeight: "bold", color: "black" }}
                to={"/building/" + b._id}
              >
                {b.name}
              </Link>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div style={{ background: "#eee" }}>
        <div className="ui top attached inverted menu">
          <div className="item">
            {this.state.buildings === undefined ? (
              <div className="ui small active inline loader"></div>
            ) : (
              <div> You have {this.state.buildings.length} Buildings </div>
            )}
          </div>
          <div className="right menu">
            <div className="item">
              <span>Sort By: &nbsp;&nbsp;</span>
              <div className="ui buttons">
                <button
                  ref={(ref) => (this.btnSortTime = ref)}
                  className="ui active button"
                  onClick={() => {
                    this.btnSortTime.classList.add("active");
                    this.btnSortAlpha.classList.remove("active");
                    this.setState({
                      buildings: this.state.buildings.sort((a, b) =>
                        a._id.localeCompare(b._id)
                      ),
                    });
                  }}
                >
                  &nbsp;&nbsp;
                  <Icon name="sort numeric ascending" />
                </button>
                <div className="or"></div>
                <button
                  ref={(ref) => (this.btnSortAlpha = ref)}
                  className="ui button"
                  onClick={() => {
                    this.btnSortAlpha.classList.add("active");
                    this.btnSortTime.classList.remove("active");
                    this.setState({
                      buildings: this.state.buildings.sort((a, b) =>
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                      ),
                    });
                  }}
                >
                  &nbsp;&nbsp;
                  <Icon name="sort alphabet ascending" />
                </button>
              </div>
            </div>
            <div className="item">
              <Link
                className="ui primary labeled icon button"
                to="/buildings/new"
              >
                <Icon name="plus" />
                ADD BUILDING
              </Link>
            </div>
          </div>
        </div>

        <div
          className="ui fluid container"
          style={{ marginTop: "1em", padding: "1em" }}
        >
          {buildingCards}
        </div>
      </div>
    );
  }
}
export default Buildings;
