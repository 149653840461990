import React from "react";
import { chain, compact, find } from "underscore";
import moment from "moment";
import { Icon, Button } from "semantic-ui-react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../styles/ag_grid.css";

import { api, ws } from "../api";
import BuildingGroupPanel from "../components/BuildingGroupPanel/BuildingGroupPanel";
import BuildingMenu from "../components/BuildingMenu";
import * as DPT from "../components/DPTComboBox/dpt";
import MultiGroupPanel from "../components/BuildingGroupPanel/MultiGroupPanel";
import { cloneDeep } from "lodash";

class BuildingGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnDefs: [
        {
          headerName: "Address",
          field: "text",
          width: 170,
          cellRenderer: "agGroupCellRenderer",
          cellRendererParams: {
            checkbox: true,
            suppressCount: true,
          },
        },
        { headerName: "Name", field: "name", width: 200 },
        {
          headerName: "Data Type",
          field: "dpt",
          width: 80,
          cellRenderer: (params) => {
            if (params.data._dpt) {
              return params.data._dpt.text;
            }
            return "";
          },
        },
        {
          headerName: "Value",
          field: "lastValue",
          width: 100,
          cellRenderer: (params) => {
            if (
              params.data._formatter &&
              params.value !== undefined &&
              params.value !== null
            ) {
              return (
                params.data._formatter(params.value) +
                (params.data._dpt && params.data._dpt.unit
                  ? " " + params.data._dpt.unit
                  : "")
              );
            }
            return "";
          },
        },
      ],
      rowData: [],
      multipleSelected: false,
      groupSelect: [],
    };

    this.gridOptions = {
      defaultColDef: { resizable: true },
      rowSelection: "multiple",
      groupSelectsChildren: true,
      angularCompileHeaders: true,
      groupSelectsFiltered: true,
      suppressCellSelection: true,
      suppressMovableColumns: true,
      getNodeChildDetails: (rowItem) => {
        if (rowItem.children) {
          return {
            group: true,
            children: rowItem.children,
            field: "group",
            key: rowItem.text,
          };
        } else {
          return null;
        }
      },
      // have checkbox on the group column
      autoGroupColumnDef: {
        cellRendererParams: {
          checkbox: true,
        },
      },
      onSelectionChanged: this.alertOnSelectionChanged,
    };
    this.populateGrid = this.populateGrid.bind(this);
    this.props.setBuildingId(this.props.match.params.buildingId);
  }

  updateGrid() {
    this.fetchBuildingDevices(() => {
      this.fetchBuildingGroups(() => {
        //remove loading animation
        this.setLoading(false);

        let newState = { groupSrc: this.state.groups };

        newState.groups = compact(
          Object.keys(this.state.groups).map((key) => {
            let grp = this.state.groups[key];
            grp.bulkEdit = Math.random() < 0.5;
            grp.id = key;
            return grp;
          })
        );

        //generate tree strucutre for multi level group addresses
        newState.groupTree = this.genTree(this.state.groups);

        this.setState(newState, this.populateGrid);
      });
    });
    this.setState({ multipleSelected: false });
  }

  componentDidMount() {
    this.updateGrid();
  }

  populateGrid = () => {
    if (this.gridApi) {
      this.gridApi.setRowData(this.state.groupTree);
      this.gridApi.sizeColumnsToFit();

      //start websocket
      ws().then((socket) => {
        this.socket = socket;
        socket.on("datagram", (data) => {
          // check this group is in row data
          let g = this.state.groupSrc[data.group];
          if (g === undefined) return;

          //upodate last value and grid
          g.lastValue = data.value;
          g.lastValueTimestamp = moment().toISOString();

          if (this._refreshTimer === undefined) {
            this._refreshTimer = setTimeout(() => {
              this.gridApi.refreshCells();
              this._refreshTimer = undefined;
            }, 300);
          }

          if (
            this.state.selectedGroup &&
            this.state.selectedGroup.id === data.group &&
            g._dpt &&
            g._formatter
          ) {
            //update selected group panel last value
            let lastValuef = undefined;
            lastValuef = g._formatter(g.lastValue);
            if (g._dpt.unit) lastValuef += " " + g._dpt.unit;
            this.setState({
              selectedGroupLastValue: lastValuef,
              selectedGrouplastValueTime: moment().format(
                "DD-MM-YYYY HH:mm:ss"
              ),
            });
          }
        });
        socket.emit("subscribe", {
          building: this.props.match.params.buildingId,
        });
      });
    }
  };

  componentWillUnmount() {
    this.closed = true;
    if (this.socket) this.socket.close();
  }

  setLoading(isLoading) {
    var loadingEles = document.querySelectorAll("._page.segment");
    if (loadingEles)
      Array.prototype.forEach.call(loadingEles, (ele) => {
        if (isLoading) ele.classList.add("loading");
        else ele.classList.remove("loading");
      });
  }

  fetchBuildingGroups(cb) {
    api("/building/group/" + this.props.match.params.buildingId).then((res) => {
      if (this.closed === true) return;
      if (res.groups) {
        this.setState({ groups: res.groups }, () => {
          cb();
        });
      } else cb();
    });
  }
  fetchBuildingDevices(cb) {
    api("/building/device/" + this.props.match.params.buildingId).then(
      (res) => {
        if (this.closed === true) return;
        if (res.devices)
          this.setState({ devices: res.devices }, () => {
            cb();
          });
        else cb();
      }
    );
  }

  alertOnSelectionChanged = (event) => {
    // const selectedNodes = event.api.getSelectedNodes();
    // var rowCount = selectedNodes.length;
    // console.log("selection changed, " + rowCount + " rows selected");
    // console.dir(selectedNodes);
    const selectedNodes = event.api.getSelectedNodes();
    var rowCount = selectedNodes.length;

    this.setState({ selectedGroup: undefined, multipleSelected: false });
    switch (rowCount) {
      case 0:
        break;
      case 1:
        {
          const selectedNode = selectedNodes[0];
          if (selectedNode.selected !== true) return;
          if (selectedNode.data.id) {
            let selectedNodeData = selectedNode.data;
            let lastValuef = formatLastValue(selectedNodeData);
            selectedNodeData._com = getComObject(
              selectedNodeData,
              this.state.devices
            );

            this.setState(
              {
                selectedGroup: selectedNodeData,
                selectedGroupLastValue: lastValuef,
                selectedGrouplastValueTime: selectedNodeData.lastValueTimestamp
                  ? moment(selectedNodeData.lastValueTimestamp).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )
                  : undefined,
              },
              () => {
                // load history
                //this.refreshHistoryChart(selectedNode.data.id)
              }
            );
          }
        }
        break;
      default: {
        this.setState({ multipleSelected: true });
        const selectedNodesFormatted = selectedNodes.map((selectedNode) => {
          if (selectedNode.data.id) {
            let formattedNode = cloneDeep(selectedNode.data);
            formattedNode._formatter = selectedNode.data._formatter;
            formattedNode.lastValuef = formatLastValue(formattedNode);
            formattedNode._com = getComObject(
              formattedNode,
              this.state.devices
            );
            return formattedNode;
          }
        });
        this.setState({ groupSelect: selectedNodesFormatted });
      }
    }
  };

  render() {
    let treeGrid = (
      <div className="ag-grid ag-theme-balham">
        <AgGridReact
          onGridReady={(args) => {
            this.gridApi = args.api;
            args.api.sizeColumnsToFit();
          }}
          gridOptions={this.gridOptions}
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          rowHeight="25"
        />
      </div>
    );

    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...this.props} />

        <div
          className="ui _page loading segment"
          style={{
            height: "calc(100vh - 77px)",
            width: "calc(50% - 5px)",
            margin: 0,
            border: "none",
            float: "left",
          }}
        >
          <div className="ui top attached menu">
            <div className="active item">
              <Icon name="desktop" />
              Groups
            </div>
            <div className="active item">
              <Button onClick={() => this.gridApi.expandAll()}>
                <Icon name="expand" />
                Expand all
              </Button>
            </div>
            <div className="active item">
              <Button onClick={() => this.gridApi.collapseAll()}>
                <Icon name="compress" />
                Collapse all
              </Button>
            </div>
            <div className="right menu">
              <div className="ui right aligned category search item">
                <div className="ui transparent icon input">
                  <input
                    className="prompt"
                    type="text"
                    placeholder="Filter ..."
                    onChange={(event) => {
                      if (this.gridApi) {
                        if (!this._expanded) {
                          this._expanded = true;
                          this.gridApi.expandAll();
                        }
                        this.gridApi.setQuickFilter(event.target.value);
                      }
                    }}
                  />
                  <Icon name="search" className="link" />
                </div>
                <div className="results" />
              </div>
            </div>
          </div>
          <div
            className="ui bottom attached segment"
            style={{ height: "calc(100% - 40px)", padding: 0 }}
          >
            {treeGrid}
          </div>
        </div>

        {this.state.multipleSelected ? (
          <MultiGroupPanel
            style={{ float: "left", width: "calc(50% - 12px)", marginLeft: 10 }}
            groupSelect={this.state.groupSelect}
            buildingId={this.props.match.params.buildingId}
            onChanged={() => {
              this.updateGrid();
            }}
          />
        ) : (
          <BuildingGroupPanel
            style={{ float: "left", width: "calc(50% - 12px)", marginLeft: 10 }}
            group={this.state.selectedGroup || {}}
            lastValue={this.state.selectedGroupLastValue}
            lastValueTime={this.state.selectedGrouplastValueTime}
            params={this.props.match.params}
            onChanged={() => {
              this.gridApi.refreshCells();
            }}
          />
        )}
      </div>
    );
  }

  milkGroupByLevel(groups, level) {
    var re = chain(groups)
      .filter(function (g) {
        if (g["isRange"]) return false;
        return true;
      })
      .groupBy(function (group) {
        var lvls = group.id.split("/");
        if (lvls.length <= level + 1) return "NOMORE";
        return lvls[level];
      })
      .value();
    return re;
  }
  genTree(nodes, level) {
    var self = this;
    if (!level) level = 0;
    if (level > 2) return [];
    var mapGroup = this.milkGroupByLevel(nodes, level);
    if (mapGroup.NOMORE) {
      return mapGroup.NOMORE.map((o) => {
        let g = o;
        //fill DPT object
        if (g.dpt !== undefined && g._formatter === undefined) {
          let dpt = DPT.getByName(g.dpt);
          if (dpt) {
            g._dpt = dpt;
            g._formatter = dpt.formatter;
          }
        }
        return Object.assign(o, {
          id: o.id,
          text: !level === 2 ? o["name"] : o.id,
        });
      });
    }
    return chain(mapGroup)
      .map((v, k) => {
        var text = "Unknown";
        var levelIds = v[0].id.split("/");
        var levelId = "";
        for (var i = 0; i <= level; i++) {
          levelId += "/" + levelIds[i];
        }
        levelId = levelId.substring(1);
        var rangeGroup = find(self.state.groups, { id: levelId });
        if (rangeGroup) text = rangeGroup["name"];

        var re = {
          id: levelId,
          text: "" + k + ". " + text,
          disabled: true,
          children: self.genTree(v, level + 1),
        };
        if (re.children.length === 0) delete re.children;

        return re;
      })
      .value();
  }
}

const formatLastValue = (selectedNodeData) => {
  let lastValuef = undefined;
  if (selectedNodeData._dpt && selectedNodeData._formatter) {
    lastValuef = selectedNodeData._formatter(selectedNodeData.lastValue);
    if (selectedNodeData._dpt.unit)
      lastValuef += " " + selectedNodeData._dpt.unit;
  }
  return lastValuef;
};

const getComObject = (selectedNodeData, devices) => {
  //get com object
  if (selectedNodeData._com === undefined) {
    for (const dev in devices) {
      if (devices[dev].coms) {
        for (const i in devices[dev].coms)
          if (
            devices[dev].coms[i].group &&
            devices[dev].coms[i].group === selectedNodeData.id
          ) {
            return devices[dev].coms[i];
          }
      }
    }
  } else {
    return selectedNodeData._com;
  }
  return {};
};

export default BuildingGroups;
