//Generated by tools/importETSMaster.js.
//	DO NOT EDIT

module.exports = {
  "DPT-1": {
    id: "DPT-1",
    number: 1,
    name: "1.xxx",
    text: "1-bit",
    sizeinbit: 1,
    converter: "DPTBoolean",
    children: {
      "DPST-1-1": {
        id: "DPST-1-1",
        number: 1,
        name: "Switch",
        text: "switch",
        sizeinbit: 1,
        converter: "DPTBoolean",
        default: "true",
      },
      "DPST-1-2": {
        id: "DPST-1-2",
        number: 2,
        name: "Bool",
        text: "boolean",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-3": {
        id: "DPST-1-3",
        number: 3,
        name: "Enable",
        text: "enable",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-4": {
        id: "DPST-1-4",
        number: 4,
        name: "Ramp",
        text: "ramp",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-5": {
        id: "DPST-1-5",
        number: 5,
        name: "Alarm",
        text: "alarm",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-6": {
        id: "DPST-1-6",
        number: 6,
        name: "BinaryValue",
        text: "binary value",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-7": {
        id: "DPST-1-7",
        number: 7,
        name: "Step",
        text: "step",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-8": {
        id: "DPST-1-8",
        number: 8,
        name: "UpDown",
        text: "up/down",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-9": {
        id: "DPST-1-9",
        number: 9,
        name: "OpenClose",
        text: "open/close",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-10": {
        id: "DPST-1-10",
        number: 10,
        name: "Start",
        text: "start/stop",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-11": {
        id: "DPST-1-11",
        number: 11,
        name: "State",
        text: "state",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-12": {
        id: "DPST-1-12",
        number: 12,
        name: "Invert",
        text: "invert",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-13": {
        id: "DPST-1-13",
        number: 13,
        name: "DimSendStyle",
        text: "dim send style",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-14": {
        id: "DPST-1-14",
        number: 14,
        name: "InputSource",
        text: "input source",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-15": {
        id: "DPST-1-15",
        number: 15,
        name: "Reset",
        text: "reset",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-16": {
        id: "DPST-1-16",
        number: 16,
        name: "Ack",
        text: "acknowledge",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-17": {
        id: "DPST-1-17",
        number: 17,
        name: "Trigger",
        text: "trigger",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-18": {
        id: "DPST-1-18",
        number: 18,
        name: "Occupancy",
        text: "occupancy",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-19": {
        id: "DPST-1-19",
        number: 19,
        name: "Window_Door",
        text: "window/door",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-21": {
        id: "DPST-1-21",
        number: 21,
        name: "LogicalFunction",
        text: "logical function",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-22": {
        id: "DPST-1-22",
        number: 22,
        name: "Scene_AB",
        text: "scene",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-23": {
        id: "DPST-1-23",
        number: 23,
        name: "ShutterBlinds_Mode",
        text: "schutter/blinds mode",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
      "DPST-1-100": {
        id: "DPST-1-100",
        number: 100,
        name: "Heat_Cool",
        text: "heating/cooling",
        sizeinbit: 1,
        converter: "DPTBoolean",
      },
    },
  },
  "DPT-2": {
    id: "DPT-2",
    number: 2,
    name: "2.xxx",
    text: "1-bit controlled",
    sizeinbit: 2,
    converter: "DPT1BitControlled",
    children: {
      "DPST-2-1": {
        id: "DPST-2-1",
        number: 1,
        name: "Switch_Control",
        text: "switch control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
        default: "true",
      },
      "DPST-2-2": {
        id: "DPST-2-2",
        number: 2,
        name: "Bool_Control",
        text: "boolean control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-3": {
        id: "DPST-2-3",
        number: 3,
        name: "Enable_Control",
        text: "enable control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-4": {
        id: "DPST-2-4",
        number: 4,
        name: "Ramp_Control",
        text: "ramp control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-5": {
        id: "DPST-2-5",
        number: 5,
        name: "Alarm_Control",
        text: "alarm control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-6": {
        id: "DPST-2-6",
        number: 6,
        name: "BinaryValue_Control",
        text: "binary value control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-7": {
        id: "DPST-2-7",
        number: 7,
        name: "Step_Control",
        text: "step control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-8": {
        id: "DPST-2-8",
        number: 8,
        name: "Direction1_Control",
        text: "direction control 1",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-9": {
        id: "DPST-2-9",
        number: 9,
        name: "Direction2_Control",
        text: "direction control 2",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-10": {
        id: "DPST-2-10",
        number: 10,
        name: "Start_Control",
        text: "start control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-11": {
        id: "DPST-2-11",
        number: 11,
        name: "State_Control",
        text: "state control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-2-12": {
        id: "DPST-2-12",
        number: 12,
        name: "Invert_Control",
        text: "invert control",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
    },
  },
  "DPT-3": {
    id: "DPT-3",
    number: 3,
    name: "3.xxx",
    text: "3-bit controlled",
    sizeinbit: 4,
    converter: "DPT3BitControlled",
    children: {
      "DPST-3-7": {
        id: "DPST-3-7",
        number: 7,
        name: "Control_Dimming",
        text: "dimming control",
        sizeinbit: 4,
        converter: "DPT3BitControlled",
        default: "true",
      },
      "DPST-3-8": {
        id: "DPST-3-8",
        number: 8,
        name: "Control_Blinds",
        text: "blind control",
        sizeinbit: 4,
        converter: "DPT3BitControlled",
      },
    },
  },
  "DPT-4": {
    id: "DPT-4",
    number: 4,
    name: "4.xxx",
    text: "character",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-4-1": {
        id: "DPST-4-1",
        number: 1,
        name: "Char_ASCII",
        text: "character (ASCII)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-4-2": {
        id: "DPST-4-2",
        number: 2,
        name: "Char_8859_1",
        text: "character (ISO 8859-1)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-5": {
    id: "DPT-5",
    number: 5,
    name: "5.xxx",
    text: "8-bit unsigned value",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-5-1": {
        id: "DPST-5-1",
        number: 1,
        name: "Scaling",
        text: "percentage (0..100%)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
        default: "true",
      },
      "DPST-5-3": {
        id: "DPST-5-3",
        number: 3,
        name: "Angle",
        text: "angle (degrees)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-5-4": {
        id: "DPST-5-4",
        number: 4,
        name: "Percent_U8",
        text: "percentage (0..255%)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-5-5": {
        id: "DPST-5-5",
        number: 5,
        name: "DecimalFactor",
        text: "ratio (0..255)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-5-6": {
        id: "DPST-5-6",
        number: 6,
        name: "Tariff",
        text: "tariff (0..255)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-5-10": {
        id: "DPST-5-10",
        number: 10,
        name: "Value_1_Ucount",
        text: "counter pulses (0..255)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-6": {
    id: "DPT-6",
    number: 6,
    name: "6.xxx",
    text: "8-bit signed value",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-6-1": {
        id: "DPST-6-1",
        number: 1,
        name: "Percent_V8",
        text: "percentage (-128..127%)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-6-10": {
        id: "DPST-6-10",
        number: 10,
        name: "Value_1_Count",
        text: "counter pulses (-128..127)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-6-20": {
        id: "DPST-6-20",
        number: 20,
        name: "Status_Mode3",
        text: "status with mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-7": {
    id: "DPT-7",
    number: 7,
    name: "7.xxx",
    text: "2-byte unsigned value",
    sizeinbit: 16,
    converter: "DPT2ByteUnsigned",
    children: {
      "DPST-7-1": {
        id: "DPST-7-1",
        number: 1,
        name: "Value_2_Ucount",
        text: "pulses",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-7-2": {
        id: "DPST-7-2",
        number: 2,
        name: "TimePeriodMsec",
        text: "time",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "ms",
      },
      "DPST-7-3": {
        id: "DPST-7-3",
        number: 3,
        name: "TimePeriod10Msec",
        text: "time (10 ms)",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-7-4": {
        id: "DPST-7-4",
        number: 4,
        name: "TimePeriod100Msec",
        text: "time (100 ms)",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-7-5": {
        id: "DPST-7-5",
        number: 5,
        name: "TimePeriodSec",
        text: "time",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "s",
      },
      "DPST-7-6": {
        id: "DPST-7-6",
        number: 6,
        name: "TimePeriodMin",
        text: "time",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "min",
      },
      "DPST-7-7": {
        id: "DPST-7-7",
        number: 7,
        name: "TimePeriodHrs",
        text: "time",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "h",
      },
      "DPST-7-10": {
        id: "DPST-7-10",
        number: 10,
        name: "PropDataType",
        text: "property data type",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-7-11": {
        id: "DPST-7-11",
        number: 11,
        name: "Length_mm",
        text: "length",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "mm",
      },
      "DPST-7-12": {
        id: "DPST-7-12",
        number: 12,
        name: "UElCurrentmA",
        text: "current",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "mA",
      },
      "DPST-7-13": {
        id: "DPST-7-13",
        number: 13,
        name: "Brightness",
        text: "brightness",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "lux",
      },
    },
  },
  "DPT-8": {
    id: "DPT-8",
    number: 8,
    name: "8.xxx",
    text: "2-byte signed value",
    sizeinbit: 16,
    converter: "DPT2ByteUnsigned",
    children: {
      "DPST-8-1": {
        id: "DPST-8-1",
        number: 1,
        name: "Value_2_Count",
        text: "pulses difference",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-8-2": {
        id: "DPST-8-2",
        number: 2,
        name: "DeltaTimeMsec",
        text: "time lag",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "ms",
      },
      "DPST-8-3": {
        id: "DPST-8-3",
        number: 3,
        name: "DeltaTime10Msec",
        text: "time lag(10 ms)",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-8-4": {
        id: "DPST-8-4",
        number: 4,
        name: "DeltaTime100Msec",
        text: "time lag (100 ms)",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-8-5": {
        id: "DPST-8-5",
        number: 5,
        name: "DeltaTimeSec",
        text: "time lag",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "s",
      },
      "DPST-8-6": {
        id: "DPST-8-6",
        number: 6,
        name: "DeltaTimeMin",
        text: "time lag",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "min",
      },
      "DPST-8-7": {
        id: "DPST-8-7",
        number: 7,
        name: "DeltaTimeHrs",
        text: "time lag",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "h",
      },
      "DPST-8-10": {
        id: "DPST-8-10",
        number: 10,
        name: "Percent_V16",
        text: "percentage difference",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "%",
      },
      "DPST-8-11": {
        id: "DPST-8-11",
        number: 11,
        name: "Rotation_Angle",
        text: "rotation angle",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
        unit: "°",
      },
    },
  },
  "DPT-9": {
    id: "DPT-9",
    number: 9,
    name: "9.xxx",
    text: "2-byte float value",
    sizeinbit: 16,
    default: "true",
    converter: "DPT2ByteFloat",
    children: {
      "DPST-9-1": {
        id: "DPST-9-1",
        number: 1,
        name: "Value_Temp",
        text: "temperature",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "°C",
      },
      "DPST-9-2": {
        id: "DPST-9-2",
        number: 2,
        name: "Value_Tempd",
        text: "temperature difference",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "K",
      },
      "DPST-9-3": {
        id: "DPST-9-3",
        number: 3,
        name: "Value_Tempa",
        text: "kelvin/hour",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "K/h",
      },
      "DPST-9-4": {
        id: "DPST-9-4",
        number: 4,
        name: "Value_Lux",
        text: "lux",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "Lux",
      },
      "DPST-9-5": {
        id: "DPST-9-5",
        number: 5,
        name: "Value_Wsp",
        text: "speed",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "m/s",
      },
      "DPST-9-6": {
        id: "DPST-9-6",
        number: 6,
        name: "Value_Pres",
        text: "pressure",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "Pa",
      },
      "DPST-9-7": {
        id: "DPST-9-7",
        number: 7,
        name: "Value_Humidity",
        text: "percentage",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "%",
      },
      "DPST-9-8": {
        id: "DPST-9-8",
        number: 8,
        name: "Value_AirQuality",
        text: "parts/million",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "ppm",
      },
      "DPST-9-10": {
        id: "DPST-9-10",
        number: 10,
        name: "Value_Time1",
        text: "time",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "s",
      },
      "DPST-9-11": {
        id: "DPST-9-11",
        number: 11,
        name: "Value_Time2",
        text: "time",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "ms",
      },
      "DPST-9-20": {
        id: "DPST-9-20",
        number: 20,
        name: "Value_Volt",
        text: "voltage",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "mV",
      },
      "DPST-9-21": {
        id: "DPST-9-21",
        number: 21,
        name: "Value_Curr",
        text: "current",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "mA",
      },
      "DPST-9-22": {
        id: "DPST-9-22",
        number: 22,
        name: "PowerDensity",
        text: "power denisity",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "W/m²",
      },
      "DPST-9-23": {
        id: "DPST-9-23",
        number: 23,
        name: "KelvinPerPercent",
        text: "kelvin/percent",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "K/%",
      },
      "DPST-9-24": {
        id: "DPST-9-24",
        number: 24,
        name: "Power",
        text: "power",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "kW",
      },
      "DPST-9-25": {
        id: "DPST-9-25",
        number: 25,
        name: "Value_Volume_Flow",
        text: "volume flow",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "l/h",
      },
      "DPST-9-26": {
        id: "DPST-9-26",
        number: 26,
        name: "Rain_Amount",
        text: "rain amount",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "l/m²",
      },
      "DPST-9-27": {
        id: "DPST-9-27",
        number: 27,
        name: "Value_Temp_F",
        text: "temperature",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "°F",
      },
      "DPST-9-28": {
        id: "DPST-9-28",
        number: 28,
        name: "Value_Wsp_kmh",
        text: "wind speed",
        sizeinbit: 16,
        default: "true",
        converter: "DPT2ByteFloat",
        unit: "km/h",
      },
    },
  },
  "DPT-10": {
    id: "DPT-10",
    number: 10,
    name: "10.xxx",
    text: "time",
    sizeinbit: 24,
    converter: "DPTTime",
    children: {
      "DPST-10-1": {
        id: "DPST-10-1",
        number: 1,
        name: "TimeOfDay",
        text: "time of day",
        sizeinbit: 24,
        converter: "DPTTime",
        default: "true",
      },
    },
  },
  "DPT-11": {
    id: "DPT-11",
    number: 11,
    name: "11.xxx",
    text: "date",
    sizeinbit: 24,
    converter: "DPTDate",
    children: {
      "DPST-11-1": {
        id: "DPST-11-1",
        number: 1,
        name: "Date",
        text: "date",
        sizeinbit: 24,
        converter: "DPTDate",
      },
    },
  },
  "DPT-12": {
    id: "DPT-12",
    number: 12,
    name: "12.xxx",
    text: "4-byte unsigned value",
    sizeinbit: 32,
    converter: "DPT4ByteSigned",
    children: {
      "DPST-12-1": {
        id: "DPST-12-1",
        number: 1,
        name: "Value_4_Ucount",
        text: "counter pulses (unsigned)",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
      },
    },
  },
  "DPT-13": {
    id: "DPT-13",
    number: 13,
    name: "13.xxx",
    text: "4-byte signed value",
    sizeinbit: 32,
    converter: "DPT4ByteSigned",
    children: {
      "DPST-13-1": {
        id: "DPST-13-1",
        number: 1,
        name: "Value_4_Count",
        text: "counter pulses (signed)",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
      },
      "DPST-13-2": {
        id: "DPST-13-2",
        number: 2,
        name: "FlowRate_m3/h",
        text: "flow rate",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "m³/h",
      },
      "DPST-13-10": {
        id: "DPST-13-10",
        number: 10,
        name: "ActiveEnergy",
        text: "active energy",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "Wh",
      },
      "DPST-13-11": {
        id: "DPST-13-11",
        number: 11,
        name: "ApparantEnergy",
        text: "apparant energy",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "VAh",
      },
      "DPST-13-12": {
        id: "DPST-13-12",
        number: 12,
        name: "ReactiveEnergy",
        text: "reactive energy",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "VARh",
      },
      "DPST-13-13": {
        id: "DPST-13-13",
        number: 13,
        name: "ActiveEnergy_kWh",
        text: "active energy",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "kWh",
      },
      "DPST-13-14": {
        id: "DPST-13-14",
        number: 14,
        name: "ApparantEnergy_kVAh",
        text: "apparent energy",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "kVAh",
      },
      "DPST-13-15": {
        id: "DPST-13-15",
        number: 15,
        name: "ReactiveEnergy_kVARh",
        text: "reactive energy",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "kVARh",
      },
      "DPST-13-100": {
        id: "DPST-13-100",
        number: 100,
        name: "LongDeltaTimeSec",
        text: "time lag",
        sizeinbit: 32,
        converter: "DPT4ByteSigned",
        unit: "s",
      },
    },
  },
  "DPT-14": {
    id: "DPT-14",
    number: 14,
    name: "14.xxx",
    text: "4-byte float value",
    sizeinbit: 32,
    default: "true",
    converter: "DPT4ByteFloat",
    children: {
      "DPST-14-0": {
        id: "DPST-14-0",
        number: 0,
        name: "Value_Acceleration",
        text: "acceleration",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m/s²",
      },
      "DPST-14-1": {
        id: "DPST-14-1",
        number: 1,
        name: "Value_Acceleration_Angular",
        text: "angular acceleration (rad/s²)",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
      },
      "DPST-14-2": {
        id: "DPST-14-2",
        number: 2,
        name: "Value_Activation_Energy",
        text: "activation energy",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "J/mol",
      },
      "DPST-14-3": {
        id: "DPST-14-3",
        number: 3,
        name: "Value_Activity",
        text: "radioactive activity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "1/s",
      },
      "DPST-14-4": {
        id: "DPST-14-4",
        number: 4,
        name: "Value_Mol",
        text: "amount of substance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "mol",
      },
      "DPST-14-5": {
        id: "DPST-14-5",
        number: 5,
        name: "Value_Amplitude",
        text: "amplitude",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
      },
      "DPST-14-6": {
        id: "DPST-14-6",
        number: 6,
        name: "Value_AngleRad",
        text: "angle (radiant)",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
      },
      "DPST-14-7": {
        id: "DPST-14-7",
        number: 7,
        name: "Value_AngleDeg",
        text: "angle (degree)",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
      },
      "DPST-14-8": {
        id: "DPST-14-8",
        number: 8,
        name: "Value_Angular_Momentum",
        text: "angular momentum",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Js",
      },
      "DPST-14-9": {
        id: "DPST-14-9",
        number: 9,
        name: "Value_Angular_Velocity",
        text: "angular velocity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "rad/s",
      },
      "DPST-14-10": {
        id: "DPST-14-10",
        number: 10,
        name: "Value_Area",
        text: "area",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m*m",
      },
      "DPST-14-11": {
        id: "DPST-14-11",
        number: 11,
        name: "Value_Capacitance",
        text: "capacitance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "F",
      },
      "DPST-14-12": {
        id: "DPST-14-12",
        number: 12,
        name: "Value_Charge_DensitySurface",
        text: "flux density",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C/m²",
      },
      "DPST-14-13": {
        id: "DPST-14-13",
        number: 13,
        name: "Value_Charge_DensityVolume",
        text: "charge density",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C/m³",
      },
      "DPST-14-14": {
        id: "DPST-14-14",
        number: 14,
        name: "Value_Compressibility",
        text: "compressibility",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m²/N",
      },
      "DPST-14-15": {
        id: "DPST-14-15",
        number: 15,
        name: "Value_Conductance",
        text: "conductance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "S",
      },
      "DPST-14-16": {
        id: "DPST-14-16",
        number: 16,
        name: "Value_Electrical_Conductivity",
        text: "conductivity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "S/m",
      },
      "DPST-14-17": {
        id: "DPST-14-17",
        number: 17,
        name: "Value_Density",
        text: "density",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "kg/m³",
      },
      "DPST-14-18": {
        id: "DPST-14-18",
        number: 18,
        name: "Value_Electric_Charge",
        text: "electric charge",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C",
      },
      "DPST-14-19": {
        id: "DPST-14-19",
        number: 19,
        name: "Value_Electric_Current",
        text: "electric current",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "A",
      },
      "DPST-14-20": {
        id: "DPST-14-20",
        number: 20,
        name: "Value_Electric_CurrentDensity",
        text: "electric current density",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "A/m²",
      },
      "DPST-14-21": {
        id: "DPST-14-21",
        number: 21,
        name: "Value_Electric_DipoleMoment",
        text: "electric dipole moment",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Cm",
      },
      "DPST-14-22": {
        id: "DPST-14-22",
        number: 22,
        name: "Value_Electric_Displacement",
        text: "electric displacement",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C/m²",
      },
      "DPST-14-23": {
        id: "DPST-14-23",
        number: 23,
        name: "Value_Electric_FieldStrength",
        text: "electric field strength",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "V/m",
      },
      "DPST-14-24": {
        id: "DPST-14-24",
        number: 24,
        name: "Value_Electric_Flux",
        text: "electric flux",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C",
      },
      "DPST-14-25": {
        id: "DPST-14-25",
        number: 25,
        name: "Value_Electric_FluxDensity",
        text: "electric flux density",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C/m²",
      },
      "DPST-14-26": {
        id: "DPST-14-26",
        number: 26,
        name: "Value_Electric_Polarization",
        text: "electric polarization",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "C/m²",
      },
      "DPST-14-27": {
        id: "DPST-14-27",
        number: 27,
        name: "Value_Electric_Potential",
        text: "electric potential",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "V",
      },
      "DPST-14-28": {
        id: "DPST-14-28",
        number: 28,
        name: "Value_Electric_PotentialDifference",
        text: "electric potential difference",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "V",
      },
      "DPST-14-29": {
        id: "DPST-14-29",
        number: 29,
        name: "Value_ElectromagneticMoment",
        text: "electromagnetic moment",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Am²",
      },
      "DPST-14-30": {
        id: "DPST-14-30",
        number: 30,
        name: "Value_Electromotive_Force",
        text: "electromotive force",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "V",
      },
      "DPST-14-31": {
        id: "DPST-14-31",
        number: 31,
        name: "Value_Energy",
        text: "energy",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "J",
      },
      "DPST-14-32": {
        id: "DPST-14-32",
        number: 32,
        name: "Value_Force",
        text: "force",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "N",
      },
      "DPST-14-33": {
        id: "DPST-14-33",
        number: 33,
        name: "Value_Frequency",
        text: "frequency",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Hz",
      },
      "DPST-14-34": {
        id: "DPST-14-34",
        number: 34,
        name: "Value_Angular_Frequency",
        text: "angular frequency",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "rad/s",
      },
      "DPST-14-35": {
        id: "DPST-14-35",
        number: 35,
        name: "Value_Heat_Capacity",
        text: "heat capacity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "J/K",
      },
      "DPST-14-36": {
        id: "DPST-14-36",
        number: 36,
        name: "Value_Heat_FlowRate",
        text: "heat flow rate",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "W",
      },
      "DPST-14-37": {
        id: "DPST-14-37",
        number: 37,
        name: "Value_Heat_Quantity",
        text: "heat quantity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
      },
      "DPST-14-38": {
        id: "DPST-14-38",
        number: 38,
        name: "Value_Impedance",
        text: "impedance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Ω",
      },
      "DPST-14-39": {
        id: "DPST-14-39",
        number: 39,
        name: "Value_Length",
        text: "length",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m",
      },
      "DPST-14-40": {
        id: "DPST-14-40",
        number: 40,
        name: "Value_Light_Quantity",
        text: "light quantity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "J",
      },
      "DPST-14-41": {
        id: "DPST-14-41",
        number: 41,
        name: "Value_Luminance",
        text: "luminance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "cd/m²",
      },
      "DPST-14-42": {
        id: "DPST-14-42",
        number: 42,
        name: "Value_Luminous_Flux",
        text: "luminous flux",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "lm",
      },
      "DPST-14-43": {
        id: "DPST-14-43",
        number: 43,
        name: "Value_Luminous_Intensity",
        text: "luminous intensity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "cd",
      },
      "DPST-14-44": {
        id: "DPST-14-44",
        number: 44,
        name: "Value_Magnetic_FieldStrength",
        text: "magnetic field strength",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "A/m",
      },
      "DPST-14-45": {
        id: "DPST-14-45",
        number: 45,
        name: "Value_Magnetic_Flux",
        text: "magnetic flux",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Wb",
      },
      "DPST-14-46": {
        id: "DPST-14-46",
        number: 46,
        name: "Value_Magnetic_FluxDensity",
        text: "magnetic flux density",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "T",
      },
      "DPST-14-47": {
        id: "DPST-14-47",
        number: 47,
        name: "Value_Magnetic_Moment",
        text: "magnetic moment",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Am²",
      },
      "DPST-14-48": {
        id: "DPST-14-48",
        number: 48,
        name: "Value_Magnetic_Polarization",
        text: "magnetic polarization",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "T",
      },
      "DPST-14-49": {
        id: "DPST-14-49",
        number: 49,
        name: "Value_Magnetization",
        text: "magnetization",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "A/m",
      },
      "DPST-14-50": {
        id: "DPST-14-50",
        number: 50,
        name: "Value_MagnetomotiveForce",
        text: "magnetomotive force",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "A",
      },
      "DPST-14-51": {
        id: "DPST-14-51",
        number: 51,
        name: "Value_Mass",
        text: "mass",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "kg",
      },
      "DPST-14-52": {
        id: "DPST-14-52",
        number: 52,
        name: "Value_MassFlux",
        text: "mass flux",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "kg/s",
      },
      "DPST-14-53": {
        id: "DPST-14-53",
        number: 53,
        name: "Value_Momentum",
        text: "momentum",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "N/s",
      },
      "DPST-14-54": {
        id: "DPST-14-54",
        number: 54,
        name: "Value_Phase_AngleRad",
        text: "phase angle",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "rad",
      },
      "DPST-14-55": {
        id: "DPST-14-55",
        number: 55,
        name: "Value_Phase_AngleDeg",
        text: "phase angle",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "°",
      },
      "DPST-14-56": {
        id: "DPST-14-56",
        number: 56,
        name: "Value_Power",
        text: "power",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "W",
      },
      "DPST-14-57": {
        id: "DPST-14-57",
        number: 57,
        name: "Value_Power_Factor",
        text: "power factor (cos Φ)",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
      },
      "DPST-14-58": {
        id: "DPST-14-58",
        number: 58,
        name: "Value_Pressure",
        text: "pressure",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Pa",
      },
      "DPST-14-59": {
        id: "DPST-14-59",
        number: 59,
        name: "Value_Reactance",
        text: "reactance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Ω",
      },
      "DPST-14-60": {
        id: "DPST-14-60",
        number: 60,
        name: "Value_Resistance",
        text: "resistance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Ω",
      },
      "DPST-14-61": {
        id: "DPST-14-61",
        number: 61,
        name: "Value_Resistivity",
        text: "resistivity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Ωm",
      },
      "DPST-14-62": {
        id: "DPST-14-62",
        number: 62,
        name: "Value_SelfInductance",
        text: "self inductance",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "H",
      },
      "DPST-14-63": {
        id: "DPST-14-63",
        number: 63,
        name: "Value_SolidAngle",
        text: "solid angle",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "sr",
      },
      "DPST-14-64": {
        id: "DPST-14-64",
        number: 64,
        name: "Value_Sound_Intensity",
        text: "sound intensity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "W/m²",
      },
      "DPST-14-65": {
        id: "DPST-14-65",
        number: 65,
        name: "Value_Speed",
        text: "speed",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m/s",
      },
      "DPST-14-66": {
        id: "DPST-14-66",
        number: 66,
        name: "Value_Stress",
        text: "stress",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Pa",
      },
      "DPST-14-67": {
        id: "DPST-14-67",
        number: 67,
        name: "Value_Surface_Tension",
        text: "surface tension",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "N/m",
      },
      "DPST-14-68": {
        id: "DPST-14-68",
        number: 68,
        name: "Value_Common_Temperature",
        text: "temperature",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "°C",
      },
      "DPST-14-69": {
        id: "DPST-14-69",
        number: 69,
        name: "Value_Absolute_Temperature",
        text: "temperature absolute",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "°C",
      },
      "DPST-14-70": {
        id: "DPST-14-70",
        number: 70,
        name: "Value_TemperatureDifference",
        text: "temperature difference",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "K",
      },
      "DPST-14-71": {
        id: "DPST-14-71",
        number: 71,
        name: "Value_Thermal_Capacity",
        text: "thermal capacity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "J/K",
      },
      "DPST-14-72": {
        id: "DPST-14-72",
        number: 72,
        name: "Value_Thermal_Conductivity",
        text: "thermal conductivity",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "W/mK",
      },
      "DPST-14-73": {
        id: "DPST-14-73",
        number: 73,
        name: "Value_ThermoelectricPower",
        text: "thermoelectric power",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "V/K",
      },
      "DPST-14-74": {
        id: "DPST-14-74",
        number: 74,
        name: "Value_Time",
        text: "time",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "s",
      },
      "DPST-14-75": {
        id: "DPST-14-75",
        number: 75,
        name: "Value_Torque",
        text: "torque",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "Nm",
      },
      "DPST-14-76": {
        id: "DPST-14-76",
        number: 76,
        name: "Value_Volume",
        text: "volume",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m³",
      },
      "DPST-14-77": {
        id: "DPST-14-77",
        number: 77,
        name: "Value_Volume_Flux",
        text: "volume flux",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "m³/s",
      },
      "DPST-14-78": {
        id: "DPST-14-78",
        number: 78,
        name: "Value_Weight",
        text: "weight",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "N",
      },
      "DPST-14-79": {
        id: "DPST-14-79",
        number: 79,
        name: "Value_Work",
        text: "work",
        sizeinbit: 32,
        default: "true",
        converter: "DPT4ByteFloat",
        unit: "J",
      },
    },
  },
  "DPT-15": {
    id: "DPT-15",
    number: 15,
    name: "15.xxx",
    text: "entrance access",
    sizeinbit: 32,
    converter: "DPT4ByteUnsigned",
    children: {
      "DPST-15-0": {
        id: "DPST-15-0",
        number: 0,
        name: "Access_Data",
        text: "entrance access",
        sizeinbit: 32,
        converter: "DPT4ByteUnsigned",
      },
    },
  },
  "DPT-16": {
    id: "DPT-16",
    number: 16,
    name: "16.xxx",
    text: "character string",
    sizeinbit: 112,
    converter: "DPTString",
    children: {
      "DPST-16-0": {
        id: "DPST-16-0",
        number: 0,
        name: "String_ASCII",
        text: "Character String (ASCII)",
        sizeinbit: 112,
        converter: "DPTString",
      },
      "DPST-16-1": {
        id: "DPST-16-1",
        number: 1,
        name: "String_8859_1",
        text: "Character String (ISO 8859-1)",
        sizeinbit: 112,
        converter: "DPTString",
        default: "true",
      },
    },
  },
  "DPT-17": {
    id: "DPT-17",
    number: 17,
    name: "17.xxx",
    text: "scene number",
    sizeinbit: 8,
    converter: "DPTSceneNumber",
    children: {
      "DPST-17-1": {
        id: "DPST-17-1",
        number: 1,
        name: "SceneNumber",
        text: "scene number",
        sizeinbit: 8,
        converter: "DPTSceneNumber",
      },
    },
  },
  "DPT-18": {
    id: "DPT-18",
    number: 18,
    name: "18.xxx",
    text: "scene control",
    sizeinbit: 8,
    converter: "DPTSceneControl",
    children: {
      "DPST-18-1": {
        id: "DPST-18-1",
        number: 1,
        name: "SceneControl",
        text: "scene control",
        sizeinbit: 8,
        converter: "DPTSceneControl",
      },
    },
  },
  "DPT-19": {
    id: "DPT-19",
    number: 19,
    name: "19.xxx",
    text: "Date Time",
    sizeinbit: 64,
    converter: "DPTDateTime",
    children: {
      "DPST-19-1": {
        id: "DPST-19-1",
        number: 1,
        name: "DateTime",
        text: "date time",
        sizeinbit: 64,
        converter: "DPTDateTime",
        default: "true",
      },
    },
  },
  "DPT-20": {
    id: "DPT-20",
    number: 20,
    name: "20.xxx",
    text: "1-byte",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-20-1": {
        id: "DPST-20-1",
        number: 1,
        name: "SCLOMode",
        text: "SCLO mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-2": {
        id: "DPST-20-2",
        number: 2,
        name: "BuildingMode",
        text: "building mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-3": {
        id: "DPST-20-3",
        number: 3,
        name: "OccMode",
        text: "occupied",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-4": {
        id: "DPST-20-4",
        number: 4,
        name: "Priority",
        text: "priority",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-5": {
        id: "DPST-20-5",
        number: 5,
        name: "LightApplicationMode",
        text: "light application mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-6": {
        id: "DPST-20-6",
        number: 6,
        name: "ApplicationArea",
        text: "light application area",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-7": {
        id: "DPST-20-7",
        number: 7,
        name: "AlarmClassType",
        text: "alarm class",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-8": {
        id: "DPST-20-8",
        number: 8,
        name: "PSUMode",
        text: "PSU mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-11": {
        id: "DPST-20-11",
        number: 11,
        name: "ErrorClass_System",
        text: "system error class",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-12": {
        id: "DPST-20-12",
        number: 12,
        name: "ErrorClass_HVAC",
        text: "HVAC error class",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-13": {
        id: "DPST-20-13",
        number: 13,
        name: "Time_Delay",
        text: "time delay",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-14": {
        id: "DPST-20-14",
        number: 14,
        name: "Beaufort_Wind_Force_Scale",
        text: "wind force scale (0..12)",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-17": {
        id: "DPST-20-17",
        number: 17,
        name: "SensorSelect",
        text: "sensor mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-20": {
        id: "DPST-20-20",
        number: 20,
        name: "ActuatorConnectType",
        text: "actuator connect type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-100": {
        id: "DPST-20-100",
        number: 100,
        name: "FuelType",
        text: "fuel type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-101": {
        id: "DPST-20-101",
        number: 101,
        name: "BurnerType",
        text: "burner type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-102": {
        id: "DPST-20-102",
        number: 102,
        name: "HVACMode",
        text: "HVAC mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-103": {
        id: "DPST-20-103",
        number: 103,
        name: "DHWMode",
        text: "DHW mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-104": {
        id: "DPST-20-104",
        number: 104,
        name: "LoadPriority",
        text: "load priority",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-105": {
        id: "DPST-20-105",
        number: 105,
        name: "HVACContrMode",
        text: "HVAC control mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-106": {
        id: "DPST-20-106",
        number: 106,
        name: "HVACEmergMode",
        text: "HVAC emergency mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-107": {
        id: "DPST-20-107",
        number: 107,
        name: "ChangeoverMode",
        text: "changeover mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-108": {
        id: "DPST-20-108",
        number: 108,
        name: "ValveMode",
        text: "valve mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-109": {
        id: "DPST-20-109",
        number: 109,
        name: "DamperMode",
        text: "damper mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-110": {
        id: "DPST-20-110",
        number: 110,
        name: "HeaterMode",
        text: "heater mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-111": {
        id: "DPST-20-111",
        number: 111,
        name: "FanMode",
        text: "fan mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-112": {
        id: "DPST-20-112",
        number: 112,
        name: "MasterSlaveMode",
        text: "master/slave mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-113": {
        id: "DPST-20-113",
        number: 113,
        name: "StatusRoomSetp",
        text: "status room setpoint",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-120": {
        id: "DPST-20-120",
        number: 120,
        name: "ADAType",
        text: "ADA type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-121": {
        id: "DPST-20-121",
        number: 121,
        name: "BackupMode",
        text: "backup mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-122": {
        id: "DPST-20-122",
        number: 122,
        name: "StartSynchronization",
        text: "start syncronization type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-600": {
        id: "DPST-20-600",
        number: 600,
        name: "Behaviour_Lock_-Unlock",
        text: "behavior lock/unlock",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-601": {
        id: "DPST-20-601",
        number: 601,
        name: "Behaviour_Bus_-Power_Up_Down",
        text: "behavior bus power up/down",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-602": {
        id: "DPST-20-602",
        number: 602,
        name: "DALI_Fade_Time",
        text: "dali fade time",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-603": {
        id: "DPST-20-603",
        number: 603,
        name: "BlinkingMode",
        text: "blink mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-604": {
        id: "DPST-20-604",
        number: 604,
        name: "LightControlMode",
        text: "light control mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-605": {
        id: "DPST-20-605",
        number: 605,
        name: "SwitchPBModel",
        text: "PB switch mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-606": {
        id: "DPST-20-606",
        number: 606,
        name: "PBAction",
        text: "PB action mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-607": {
        id: "DPST-20-607",
        number: 607,
        name: "DimmPBModel",
        text: "PB dimm mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-608": {
        id: "DPST-20-608",
        number: 608,
        name: "SwitchOnMode",
        text: "switch on mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-609": {
        id: "DPST-20-609",
        number: 609,
        name: "LoadTypeSet",
        text: "load type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-610": {
        id: "DPST-20-610",
        number: 610,
        name: "LoadTypeDetected",
        text: "load type detection",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-801": {
        id: "DPST-20-801",
        number: 801,
        name: "SABExcept-Behaviour",
        text: "SAB except behavior",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-802": {
        id: "DPST-20-802",
        number: 802,
        name: "SABBehaviour_Lock_Unlock",
        text: "SAB behavior on lock/unlock",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-803": {
        id: "DPST-20-803",
        number: 803,
        name: "SSSBMode",
        text: "SSSB mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-804": {
        id: "DPST-20-804",
        number: 804,
        name: "BlindsControlMode",
        text: "blinds control mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-1000": {
        id: "DPST-20-1000",
        number: 1000,
        name: "CommMode",
        text: "communication mode",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-1001": {
        id: "DPST-20-1001",
        number: 1001,
        name: "AddInfoTypes",
        text: "additional information type",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-1002": {
        id: "DPST-20-1002",
        number: 1002,
        name: "RF_ModeSelect",
        text: "RF mode selection",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-20-1003": {
        id: "DPST-20-1003",
        number: 1003,
        name: "RF_FilterSelect",
        text: "RF filter mode selection",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-21": {
    id: "DPT-21",
    number: 21,
    name: "21.xxx",
    text: "8-bit set",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-21-1": {
        id: "DPST-21-1",
        number: 1,
        name: "StatusGen",
        text: "general status",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-2": {
        id: "DPST-21-2",
        number: 2,
        name: "Device_Control",
        text: "device control",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-100": {
        id: "DPST-21-100",
        number: 100,
        name: "ForceSign",
        text: "forcing signal",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-101": {
        id: "DPST-21-101",
        number: 101,
        name: "ForceSignCool",
        text: "forcing signal cool",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-102": {
        id: "DPST-21-102",
        number: 102,
        name: "StatusRHC",
        text: "room heating controller status",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-103": {
        id: "DPST-21-103",
        number: 103,
        name: "StatusSDHWC",
        text: "solar DHW controller status",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-104": {
        id: "DPST-21-104",
        number: 104,
        name: "FuelTypeSet",
        text: "fuel type set",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-105": {
        id: "DPST-21-105",
        number: 105,
        name: "StatusRCC",
        text: "room cooling controller status",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-106": {
        id: "DPST-21-106",
        number: 106,
        name: "StatusAHU",
        text: "ventilation controller status",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-601": {
        id: "DPST-21-601",
        number: 601,
        name: "LightActuatorErrorInfo",
        text: "lighting actuator error information",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-1000": {
        id: "DPST-21-1000",
        number: 1000,
        name: "RF_ModeInfo",
        text: "RF communication mode info",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-1001": {
        id: "DPST-21-1001",
        number: 1001,
        name: "RF_FilterInfo",
        text: "cEMI server supported RF filtering modes",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
      "DPST-21-1010": {
        id: "DPST-21-1010",
        number: 1010,
        name: "Channel_Activation_8",
        text: "channel activation for 8 channels",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-22": {
    id: "DPT-22",
    number: 22,
    name: "22.xxx",
    text: "16-bit set",
    sizeinbit: 16,
    converter: "DPT2ByteUnsigned",
    children: {
      "DPST-22-100": {
        id: "DPST-22-100",
        number: 100,
        name: "StatusDHWC",
        text: "DHW controller status",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-22-101": {
        id: "DPST-22-101",
        number: 101,
        name: "StatusRHCC",
        text: "RHCC status",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-22-1000": {
        id: "DPST-22-1000",
        number: 1000,
        name: "Media",
        text: "media",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
      "DPST-22-1010": {
        id: "DPST-22-1010",
        number: 1010,
        name: "Channel_Activation_16",
        text: "channel activation for 16 channels",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
    },
  },
  "DPT-23": {
    id: "DPT-23",
    number: 23,
    name: "23.xxx",
    text: "2-bit set",
    sizeinbit: 2,
    converter: "DPT1BitControlled",
    children: {
      "DPST-23-1": {
        id: "DPST-23-1",
        number: 1,
        name: "OnOffAction",
        text: "on/off action",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-23-2": {
        id: "DPST-23-2",
        number: 2,
        name: "Alarm_Reaction",
        text: "alarm reaction",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-23-3": {
        id: "DPST-23-3",
        number: 3,
        name: "UpDown_Action",
        text: "up/down action",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
      "DPST-23-102": {
        id: "DPST-23-102",
        number: 102,
        name: "HVAC_PB_Action",
        text: "HVAC push button action",
        sizeinbit: 2,
        converter: "DPT1BitControlled",
      },
    },
  },
  "DPT-25": {
    id: "DPT-25",
    number: 25,
    name: "25.xxx",
    text: "2-nibble set",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-25-1000": {
        id: "DPST-25-1000",
        number: 1000,
        name: "DoubleNibble",
        text: "busy/nak repetitions",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-26": {
    id: "DPT-26",
    number: 26,
    name: "26.xxx",
    text: "8-bit set",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-26-1": {
        id: "DPST-26-1",
        number: 1,
        name: "SceneInfo",
        text: "scene information",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-27": {
    id: "DPT-27",
    number: 27,
    name: "27.xxx",
    text: "32-bit set",
    sizeinbit: 32,
    converter: "DPT4ByteUnsigned",
    children: {
      "DPST-27-1": {
        id: "DPST-27-1",
        number: 1,
        name: "CombinedInfoOnOff",
        text: "bit-combined info on/off",
        sizeinbit: 32,
        converter: "DPT4ByteUnsigned",
      },
    },
  },
  "DPT-29": {
    id: "DPT-29",
    number: 29,
    name: "29.xxx",
    text: "electrical energy",
    sizeinbit: 64,
    converter: "DPTDateTime",
    children: {
      "DPST-29-10": {
        id: "DPST-29-10",
        number: 10,
        name: "ActiveEnergy_V64",
        text: "active energy",
        sizeinbit: 64,
        converter: "DPTDateTime",
        unit: "Wh",
      },
      "DPST-29-11": {
        id: "DPST-29-11",
        number: 11,
        name: "ApparantEnergy_V64",
        text: "apparant energy",
        sizeinbit: 64,
        converter: "DPTDateTime",
        unit: "VAh",
      },
      "DPST-29-12": {
        id: "DPST-29-12",
        number: 12,
        name: "ReactiveEnergy_V64",
        text: "reactive energy",
        sizeinbit: 64,
        converter: "DPTDateTime",
        unit: "VARh",
      },
    },
  },
  "DPT-30": {
    id: "DPT-30",
    number: 30,
    name: "30.xxx",
    text: "24 times channel activation",
    sizeinbit: 24,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-30-1010": {
        id: "DPST-30-1010",
        number: 1010,
        name: "Channel_Activation_24",
        text: "activation state 0..23",
        sizeinbit: 24,
        converter: "DPT8BitUnsigned",
      },
    },
  },
  "DPT-217": {
    id: "DPT-217",
    number: 217,
    name: "217.xxx",
    text: "datapoint type version",
    sizeinbit: 16,
    converter: "DPT2ByteUnsigned",
    children: {
      "DPST-217-1": {
        id: "DPST-217-1",
        number: 1,
        name: "Version",
        text: "DPT version",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
    },
  },
  "DPT-219": {
    id: "DPT-219",
    number: 219,
    name: "219.xxx",
    text: "alarm info",
    sizeinbit: 48,
    converter: "DPTRGB",
    children: {
      "DPST-219-1": {
        id: "DPST-219-1",
        number: 1,
        name: "AlarmInfo",
        text: "alarm info",
        sizeinbit: 48,
        converter: "DPTRGB",
        default: "true",
      },
    },
  },
  "DPT-222": {
    id: "DPT-222",
    number: 222,
    name: "222.xxx",
    text: "3x 2-byte float value",
    sizeinbit: 48,
    converter: "DPTRGB",
    children: {
      "DPST-222-100": {
        id: "DPST-222-100",
        number: 100,
        name: "TempRoomSetpSetF16[3]",
        text: "room temperature setpoint",
        sizeinbit: 48,
        converter: "DPTRGB",
      },
      "DPST-222-101": {
        id: "DPST-222-101",
        number: 101,
        name: "TempRoomSetpSetShiftF16[3]",
        text: "room temperature setpoint shift",
        sizeinbit: 48,
        converter: "DPTRGB",
      },
    },
  },
  "DPT-229": {
    id: "DPT-229",
    number: 229,
    name: "229.xxx",
    text: "4-1-1 byte combined information",
    sizeinbit: 48,
    converter: "DPTRGB",
    children: {
      "DPST-229-1": {
        id: "DPST-229-1",
        number: 1,
        name: "MeteringValue",
        text: "metering value (value,encoding,cmd)",
        sizeinbit: 48,
        converter: "DPTRGB",
      },
    },
  },
  "DPT-230": {
    id: "DPT-230",
    number: 230,
    name: "230.xxx",
    text: "MBus address",
    sizeinbit: 64,
    converter: "DPTDateTime",
    children: {
      "DPST-230-1000": {
        id: "DPST-230-1000",
        number: 1000,
        name: "MBus_Address",
        text: "MBus address",
        sizeinbit: 64,
        converter: "DPTDateTime",
      },
    },
  },
  "DPT-232": {
    id: "DPT-232",
    number: 232,
    name: "232.xxx",
    text: "3-byte colour RGB",
    sizeinbit: 24,
    converter: "DPTRGB",
    children: {
      "DPST-232-600": {
        id: "DPST-232-600",
        number: 600,
        name: "Colour_RGB",
        text: "RGB value 3x(0..255)",
        sizeinbit: 24,
        converter: "DPTRGB",
      },
    },
  },
  "DPT-234": {
    id: "DPT-234",
    number: 234,
    name: "234.xxx",
    text: "language code ISO 639-1",
    sizeinbit: 16,
    converter: "DPT2ByteUnsigned",
    children: {
      "DPST-234-1": {
        id: "DPST-234-1",
        number: 1,
        name: "LanguageCodeAlpha2_ASCII",
        text: "language code (ASCII)",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
    },
  },
  "DPT-237": {
    id: "DPT-237",
    number: 237,
    name: "237.xxx",
    text: "configuration/ diagnostics",
    sizeinbit: 16,
    converter: "DPT2ByteUnsigned",
    children: {
      "DPST-237-600": {
        id: "DPST-237-600",
        number: 600,
        name: "DALI_Control_Gear_Diagnostic",
        text: "diagnostic value",
        sizeinbit: 16,
        converter: "DPT2ByteUnsigned",
      },
    },
  },
  "DPT-238": {
    id: "DPT-238",
    number: 238,
    name: "238.xxx",
    text: "configuration/ diagnostics",
    sizeinbit: 8,
    converter: "DPT8BitUnsigned",
    children: {
      "DPST-238-600": {
        id: "DPST-238-600",
        number: 600,
        name: "DALI_Diagnostics",
        text: "diagnostic value",
        sizeinbit: 8,
        converter: "DPT8BitUnsigned",
      },
    },
  },
};
