import React from "react";
import { isArray, sortBy, union, each, find } from "underscore";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { Icon } from "semantic-ui-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../styles/ag_grid.css";

import { api } from "../api";
import BuildingMenu from "../components/BuildingMenu";
import BuildingGroupPanel from "../components/BuildingGroupPanel/BuildingGroupPanel";
import * as DPT from "../components/DPTComboBox/dpt";

class BuildingDevices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: {},
      devices: {},
      parts: { children: [] },
      columnDefs: [
        {
          headerName: "Address",
          valueGetter: (params) => {return params.data.name || params.data.address;},
          width: 60,
          cellRenderer: "agGroupCellRenderer",
        },
        { headerName: "Device Name", field: "text", width: 100 },
        {
          headerName: "#COM",
          field: "coms",
          width: 30,
          cellRenderer: (params) => {
            if (!isArray(params.value)) return "";
            if (params.value.length === 0) return "";
            return params.value.length;
          },
        },
      ],
      columnDefs2: [
        { headerName: "Group Address", field: "group", width: 80 },
        { headerName: "Name", field: "name", width: 80 },
        { headerName: "Priority", field: "priority", width: 60 },
        { headerName: "Function", field: "FunctionText", width: 120 },
        {
          headerName: "DPT",
          field: "dpt",
          width: 80,
        },
        { headerName: "Type", field: "type", width: 60 },
        { headerName: "T", field: "transmit", width: 60 },
        { headerName: "W", field: "write", width: 60 },
      ],
      rowData: [],
      rowData2: [],
    };

    this.gridOptions = {
      defaultColDef: { resizable: true },
      rowSelection: "single",
      suppressCellSelection: true,
      suppressMovableColumns: true,
      getNodeChildDetails: (rowItem) => {
        if (rowItem.children) {
          return {
            group: true,
            children: rowItem.children,
            field: "group",
            key: rowItem.name,
          };
        } else {
          return null;
        }
      },
    };

    this.grid2Options = {
      defaultColDef: { resizable: true },
      rowSelection: "single",
      suppressCellSelection: true,
      suppressMovableColumns: true,
    };

    this.populateGrid = this.populateGrid.bind(this);
    this.props.setBuildingId(this.props.match.params.buildingId);
  }

  componentDidMount() {
    this.fetchBuildingGroups(() => {
      this.fetchBuildingParts(() => {
        this.fetchBuildingDevices(() => {
          //remove loading animation
          this.setLoading(false);

          //generate tree strucutre for multi level addresses
          this.setState(
            { deviceTree: this.genTree(this.state.parts) },
            this.populateGrid
          );
        });
      });
    });
  }

  populateGrid = () => {
    if (this.gridApi) {
      this.gridApi.setRowData(this.state.deviceTree);
      this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll();
    }
  };

  componentWillUnmount() {
    this.closed = true;
    if (this.socket) this.socket.close();
  }

  setLoading(isLoading) {
    var loadingEles = document.querySelectorAll("._page.segment");
    if (loadingEles)
      Array.prototype.forEach.call(loadingEles, (ele) => {
        if (isLoading === true) ele.classList.add("loading");
        else ele.classList.remove("loading");
      });
  }

  fetchBuildingGroups(cb) {
    api("/building/group/" + this.props.match.params.buildingId).then((res) => {
      if (this.closed === true) return;
      if (res.groups) {
        this.setState({ groups: res.groups }, cb);
      } else cb();
    });
  }
  fetchBuildingParts(cb) {
    api("/building/part/" + this.props.match.params.buildingId).then((res) => {
      if (this.closed === true) return;
      if (
        res.buildingParts &&
        res.buildingParts.children &&
        res.buildingParts.children.length > 0
      ) {
        this.setState({ parts: res.buildingParts }, cb);
      } else cb();
    });
  }
  fetchBuildingDevices(cb) {
    api("/building/device/" + this.props.match.params.buildingId).then(
      (res) => {
        if (this.closed === true) return;
        if (res.devices) {
          for (const d in res.devices) {
            if (res.devices[d].coms) {
              res.devices[d].coms = sortBy(res.devices[d].coms, "group");
            }
          }
          this.setState({ devices: res.devices }, cb);
        } else cb();
      }
    );
  }

  render() {
    let treeGrid = (
      <div className="ag-grid ag-theme-balham">
        <AgGridReact
          onGridReady={(args) => {
            this.gridApi = args.api;
            args.api.sizeColumnsToFit();
          }}
          gridOptions={this.gridOptions}
          columnDefs={this.state.columnDefs}
          rowData={this.state.rowData}
          onRowSelected={(event) => {
            if (event.node.selected !== true) return;
            var coms = [];
            if (event.node.data && event.node.data.coms) {
              coms = event.node.data.coms;
            }
            this.grid2Api.setRowData(coms);
          }}
          rowHeight="25"
        />
      </div>
    );

    let deviceDetailGrid = (
      <div className="ag-grid ag-theme-balham orange">
        <AgGridReact
          onGridReady={(args) => {
            this.grid2Api = args.api;
            args.api.sizeColumnsToFit();
          }}
          gridOptions={this.grid2Options}
          columnDefs={this.state.columnDefs2}
          rowData={this.state.rowData2}
          rowHeight="25"
          onRowSelected={(event) => {
            if (event.node.selected !== true) return;
            if (event.node.data) {
              let group_id = event.node.data.group;
              let group = this.state.groups[group_id];
              if (group) {
                group.id = group_id;
                group._com = event.node.data;
                let dpt = DPT.getByName(group.dpt);
                if (dpt) {
                  // console.log('adding formatter');
                  group._dpt = dpt;
                  group._formatter = dpt.formatter;
                }

                // group._dpt = DPT.getByName(group.dpt);

                this.setState(
                  {
                    selectedGroup: group,
                    selectedGroupLastValue: formatLastValue(group),
                    selectedGrouplastValueTime: group.lastValueTimestamp
                      ? moment(group.lastValueTimestamp).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )
                      : undefined,
                  });

                // console.log('1',this.state.selectedGroupLastValue) 
                // console.log('2',group);
              }
            }
          }}
        />
      </div>
    );

    return (
      <div style={{ background: "#f4f4f4" }}>
        <BuildingMenu {...this.props} />

        <div
          className="ui _page loading segment"
          style={{ height: "calc(100vh - 77px)", margin: 0, border: "none" }}
        >
          <div className="ui top attached menu">
            <div className="active item">
              <Icon name="desktop" />
              Building Parts and Devices
            </div>
            <div className="right menu">
              <div className="ui right aligned category search item">
                <div className="ui transparent icon input">
                  <input
                    className="prompt"
                    type="text"
                    placeholder="Filter ..."
                    onChange={(event) => {
                      if (this.gridApi) {
                        if (!this._expanded) {
                          this._expanded = true;
                          this.gridApi.expandAll();
                        }
                        this.gridApi.setQuickFilter(event.target.value);
                      }
                    }}
                  />
                  <Icon name="search" className="link" />
                </div>
                <div className="results"></div>
              </div>
            </div>
          </div>
          <div
            className="ui bottom attached segment building-parts-and-devices-grid"
            style={{ height: "calc(100% - 40px)", padding: 0 }}
          >
            <div className="devices-tree-grid">{treeGrid}</div>
            <div className="device-detail-grid">{deviceDetailGrid}</div>
            <div className="building-group-pannel">
              <BuildingGroupPanel
                style={{ margin: "1em", position: "relative" }}
                group={this.state.selectedGroup || {}}
                lastValue={this.state.selectedGroupLastValue}
                lastValueTime={this.state.selectedGrouplastValueTime}
                params={this.props.match.params}
                onChanged={() => {
                  // console.log('devices',this.state.selectedGroup);
                  this.grid2Api.refreshCells();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  extractModels(node) {
    var models = node.models;
    if (!models) models = [];
    if (node.nodes)
      each(node.nodes, (_node) => {
        models = union(models, this.extractModels(_node));
      });
    return models;
  }
  genTree(parts) {
    if (parts.children === undefined || parts.children.length === 0) {
      return [
        {
          address: "Undefined Building",
          children: Object.values(this.state.devices).map(
            ({ address, name, coms }) => ({
              address: address,
              text: name,
              coms: coms
                ? coms.map((com) => ({
                    ...com,
                    _dpt: DPT.resolveDPT(com.dpt),
                  }))
                : coms,
            })
          ),
        },
      ];
    }
    return parts.children.map((part) => {
      var children = [];
      if (part.children) {
        children = this.genTree(part);
      }

      if (part.devices) {
        children = union(
          children,
          part.devices.map((device) => {
            var _device = find(this.state.devices, { address: device });
            if (!_device)
              return {
                address: device,
                text: device,
                coms: [],
              };
            if (!_device.coms) _device.coms = [];
            return {
              address: _device.address,
              text: _device.name,
              coms: _device.coms,
            };
          })
        );
      }

      return {
        name: part.name,
        children: children,
      };
    });
  }
}

const formatLastValue = (selectedNodeData) => {

  console.log('formatLastValue',selectedNodeData)

  let lastValuef = undefined;
  if (selectedNodeData._dpt && selectedNodeData._formatter) {
    lastValuef = selectedNodeData._formatter(selectedNodeData.lastValue);
    if (selectedNodeData._dpt.unit)
      lastValuef += " " + selectedNodeData._dpt.unit;
  }
  return lastValuef;
};


export default BuildingDevices;
