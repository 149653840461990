import React, { useEffect, useState } from "react";
import { Checkbox } from "semantic-ui-react";

const DPTBoolean = (props) => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(props.value);
  }, []);

  useEffect(() => {
    if (props.value !== value) {
      //Perform some operation here
      setValue(props.value);
    }
  }, [props]);

  return (
    <Checkbox
      toggle
      name="public"
      label="ON/OFF"
      checked={value == "ON" || value == true}
      onChange={() => {
        if (props.onSend) {
          var val = value == "ON" || value == true;
          val = !val;
          props.onSend(val);
          setValue(val);
        }
      }}
    />
  );
};

export default DPTBoolean;
