import React from "react";

import { Icon } from "semantic-ui-react";

import { api } from "../api";
import BuildingMenu from "../components/BuildingMenu";
import LocationStep from "../components/building_settings/LocationStep";
import ConnectionStep from "../components/building_settings/ConnectionStep";
import FileImportStep from "../components/building_settings/FileImportStep";
import DeleteBuilding from "../components/building_settings/DeleteBuilding";

class BuildingSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      building: undefined,
    };
    this.props.setBuildingId(this.props.match.params.buildingId);
  }

  componentDidMount() {
    if (this.props.match.params?.buildingId) {
      // GET building definition
      api("/building/def/" + this.props.match.params.buildingId).then((res) => {
        this.setState({ building: res });
      });
      // .catch((err) => {
      //   console.log(err);
      // });
    } else {
      //New Building
      this.setState({ building: {} });
    }
  }

  handleAddNewBuilding() {
    this.setState({ errorMessage: "" });
    this.ConnectionStep.applyChanges();

    if (this.state.building.name && this.state.building.ip_host) {
      //console.log(this.state.building);
      window.$("#btnNewBuilding").addClass("disabled");
      window.$("#btnNewBuilding").addClass("loading");
      api("/buildings/new", {
        method: "POST",
        body: JSON.stringify(this.state.building),
      }).then((res) => {
        if (res.success === true) {
          window.location = "/building/settings/" + res.id;
        } else if (res.error) {
          window.$("#btnNewBuilding").removeClass("disabled");
          window.$("#btnNewBuilding").removeClass("loading");
          this.setState({ errorMessage: res.error });
        }
      });
    }
  }

  render() {
    if (this.state.building === undefined)
      return (
        <div style={{ background: "#f4f4f4" }}>
          {/* <BuildingMenu {...this.props} /> */}
          <div
            className="ui loading segment"
            style={{
              border: "none",
              padding: "10em",
              width: "100%",
              height: "calc(100% - 100px)",
              margin: "0 auto",
            }}
          ></div>
        </div>
      );

    return (
      <div style={{ background: "#f4f4f4" }}>
        {this.props.match.params?.buildingId ? (
          <BuildingMenu {...this.props} />
        ) : undefined}

        <div
          className="_page"
          style={{
            border: "none",
            padding: "1em",
            maxWidth: 1200,
            margin: "0 auto",
          }}
        >
          <div className="ui section divider" />
          {/* Connection part */}
          <h3 className="ui top attached header">
            <Icon name="plug" />
            <div className="content">
              Connection
              <span className="sub header">
                Internet address and KNX/IP Interface port number
              </span>
            </div>
          </h3>
          <div className="ui attached segment">
            {this.state.errorMessage ? (
              <div className="ui negative message">
                {this.state.errorMessage}
              </div>
            ) : null}
            <ConnectionStep
              ref={(ref) => {
                this.ConnectionStep = ref;
              }}
              building={this.state.building}
            />
          </div>

          {this.props.match.params?.buildingId ? (
            <>
              {/* File Import part */}
              <h3 className="ui top attached header">
                <Icon name="file alternate outline" />
                <div className="content">
                  File Import
                  <span className="sub header">Import ETS project</span>
                </div>
              </h3>
              <div className="ui attached segment">
                <div className="ui ignored info message">
                  <div className="header">
                    Upload your ETS project file and synchronise your Building
                    Hierarchy, Group Addresses, Devices and Descriptions.
                  </div>
                  <ul className="list">
                    <li>ETS4, ETS5 and ETS6 knxproj files are all supported.</li>
                    <li>
                      Your file will be processed in <b>temporary</b> memory to
                      extract the project information required.
                    </li>
                    <li>
                      KNXMonitor does <b>not</b> store a copy of your project
                      file on our servers.
                    </li>
                    <li>
                      For subsequent uploads, any merge conflicts with current
                      settings will prompt you for <b>confirmation</b> of the
                      changes.
                    </li>
                  </ul>
                </div>
                <FileImportStep building={this.state.building} />
              </div>
              {/* Location and Timezone part */}
              <h3 className="ui top attached header">
                <Icon name="sun outline" />
                <div className="content">
                  Location and Timezone
                  <span className="sub header">
                    Building locale settings and sunset/sunrise logic
                  </span>
                </div>
              </h3>
              <div className="ui attached segment">
                <div className="ui ignored info message">
                  <div className="header">
                    KNXMonitor can be the <b>Time</b> source for your building
                    and provide you with <b>Astro Timers</b> (Timers with Offset
                    to Sunset and Sunrise).
                  </div>
                  <ul className="list">
                    <li>
                      We synchronise <b>Time</b> with pool NTP servers
                      <a
                        href="http://www.pool.ntp.org"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Icon name="globe" />
                        http://www.pool.ntp.org
                      </a>
                    </li>
                    <li>
                      If you would like KNXMonitor to be the <b>Time</b> source
                      for your building and access <b>Astro Timer</b>{" "}
                      functionality please fill out the information below.
                    </li>
                  </ul>
                </div>
                <LocationStep building={this.state.building} />
              </div>
              <DeleteBuilding
                buildingName={this.state.building.name}
                buildingID={this.state.building._id}
                routerHistory={this.props.history}
                setBuildingId={this.props.setBuildingId}
              />
            </>
          ) : (
            ""
          )}

          {this.props.match.params?.buildingId ? undefined : (
            <div
              className="ui horizontal divider"
              style={{ margin: "1em auto" }}
            >
              <div
                className="ui center aligned header"
                style={{ marginTop: "1em" }}
              >
                <button
                  id="btnNewBuilding"
                  className="ui primary big button"
                  onClick={(e) => this.handleAddNewBuilding(e)}
                >
                  <Icon name="checkmark" />
                  Save New Building
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default BuildingSettings;
