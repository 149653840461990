import React from "react";

class DPTUnsupported extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // var domNode = ReactDOM.findDOMNode(this)
  }

  render() {
    return <div>...</div>;
  }
}
export default DPTUnsupported;
