import React, { createRef } from "react";
import { Icon } from "semantic-ui-react";
import { api } from "../../api";

const connectionButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: 0,
};

const helpButtonStyle = {
  position: "absolute",
  top: "-4.5em",
  right: "150px",
};

const saveChangeButtonStyle = {
  position: "absolute",
  right: "1em",
  bottom: "-5.4em",
  borderTop: "none",
  borderRadius: "0 0px 5px 5px",
};

class ConnectionStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  applyChanges() {
    Object.assign(this.props.building, this.state);
  }

  wrapper = createRef();

  render() {
    return (
      <div ref={this.wrapper}>
        <div className="ui form">
          <a href="http://www.knxmonitor.com/guide/" target="_blank" title="Open instructions in a new window" rel="noopener noreferrer">
            <button
              className="ui positive basic button"
              style={helpButtonStyle}
            >
              <Icon name='help' />
              Getting Started
            </button>
          </a>
          {this.state.errorMessage ? (
            <div className="ui negative message">{this.state.errorMessage}</div>
          ) : null}
          {/* Name */}
          <div className="fields">
            <div className="eight wide field">
              <label>
                Building Name
                <span className="required">*</span>
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.name}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (val === "") {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.name) {
                      this.setState({ name: val });
                      this.setState({ changed: true });
                    }
                  }}
                />
              </label>
            </div>
          </div>
          {/* IP Address */}
          <div className="fields">
            <div className="eight wide ip_host field">
              <label>
                IP Address or DNS name
                <span className="required">*</span>
                <input
                  type="text"
                  placeholder=""
                  defaultValue={this.props.building.ip_host}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (val === "") {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.ip_host) {
                      this.setState({ ip_host: val });
                      this.setState({ changed: true });
                    }
                  }}
                />
              </label>
            </div>
            <div className="two wide ip_port field">
              <label>
                Port
                <input
                  type="text"
                  placeholder="3671"
                  defaultValue={this.props.building.ip_port}
                  onChange={(e) => {
                    let val = e.target.value.trim();
                    if (isFinite(+val) === false) {
                      window.$(e.target).closest(".field").addClass("error");
                      return;
                    }
                    window.$(e.target).closest(".field").removeClass("error");
                    if (val !== this.props.building.ip_port) {
                      this.setState({ ip_port: val });
                      this.setState({ changed: true });
                    }
                  }}
                />
              </label>
            </div>
          </div>
          <div className="ui ignored info message">
            <div className="header">Security</div>
            <ul className="list">
              <li>
                Configure your firewall to only accept connections from the
                following server addresses:
                <ul className="list">
                  <li>164.92.128.194  Europe</li>
                  <li>161.35.151.228  Europe</li>
                  <li>35.189.37.118   Asia/Pacific</li>
                  <li>170.64.146.93   Asia/Pacific</li>
                </ul>
              </li>
              <li>
                If your router allows port-mapping, avoid exposing port 3671.
              </li>
            </ul>
          </div>
          {/* Start/Stop/Test Connection */}
          <div className="fields">
            {this.props.building.enabled === true ? (
              <button
                className="ui negative button"
                style={connectionButtonStyle}
                onClick={() => {
                  api("/building/stop/" + this.props.building._id, {
                    method: "POST",
                  }).then(() => {
                    this.props.building.enabled = false;
                    this.forceUpdate();
                  });
                }}
              >
                Stop Monitoring
              </button>
            ) : this.props.building.enabled === undefined ? (
              <div>
                <button
                  className="ui teal testConnection button"
                  onClick={(e) => {
                    if (window.$(e.target).hasClass("disabled")) return;
                    let btn = e.target;
                    window.$(btn).addClass("disabled");
                    window.$(btn).addClass("loading");
                    let label = window
                      .$(this.wrapper.current)
                      .find(".testResult");
                    label.removeClass("green");
                    label.removeClass("red");
                    label.html("...");
                    api("/building/test", {
                      method: "POST",
                      body: JSON.stringify({
                        ip_host:
                          this.state.ip_host ||
                          window
                            .$(this.wrapper.current)
                            .find(".ip_host.field input")
                            .val(),
                        ip_port: this.state.ip_port || 3671,
                      }),
                    }).then((res) => {
                      window.$(btn).removeClass("disabled");
                      window.$(btn).removeClass("loading");

                      let label = window
                        .$(this.wrapper.current)
                        .find(".testResult");
                      label.removeClass("green");
                      label.removeClass("red");
                      if (res.success) {
                        label.addClass("green");
                        label.html("✓ Connected");
                      } else {
                        label.addClass("red");
                        label.html(res.error);
                      }
                    });
                  }}
                >
                  Test Connection
                </button>
                <span className="ui testResult basic label"></span>
              </div>
            ) : (
              <button
                className="ui positive button"
                style={connectionButtonStyle}
                onClick={() => {
                  api("/building/start/" + this.props.building._id, {
                    method: "POST",
                  }).then((res) => {
                    if (res.success === true) {
                      this.setState({ errorMessage: "" });
                      this.props.building.enabled = true;
                      this.forceUpdate();
                    } else if (res.error) {
                      this.setState({ errorMessage: res.error });
                    }
                  });
                }}
              >
                Start Monitoring
              </button>
            )}
          </div>
          <div className="ui section divider"></div>
          {/* Default source address */}
          <div className="fields">
            <div className="six wide field">
              <label>
                Source Address{" "}
                <small style={{ fontWeight: "normal" }}>
                  for sending datagram from KNXMonitor
                </small>
              </label>
              <input
                type="text"
                placeholder="1.1.255"
                defaultValue={this.props.building.knxsource}
                onChange={(e) => {
                  let val = e.target.value.trim();
                  if (val === "") {
                    window.$(e.target).closest(".field").addClass("error");
                    return;
                  }
                  window.$(e.target).closest(".field").removeClass("error");
                  if (val !== this.props.building.knxsource) {
                    this.setState({ knxsource: val });
                    this.setState({ changed: true });
                  }
                }}
              />
            </div>
          </div>
          {/* Save change, hidden when new building */}
          {this.props.building._id && this.state.changed ? (
            <div className="ui segment" style={saveChangeButtonStyle}>
              <button
                className="ui positive button"
                onClick={() => {
                  // save changes
                  api("/building/update/" + this.props.building._id, {
                    method: "POST",
                    body: JSON.stringify(this.state),
                  }).then(() => {
                    Object.assign(this.props.building, this.state);
                    this.setState((prevState) => delete prevState.name);
                    this.setState({ changed: false });
                    this.forceUpdate();
                  });
                  // .catch(() => {
                  //   //console.log(err);
                  // });
                }}
              >
                Save Changes
              </button>
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}
export default ConnectionStep;
