import React, { useEffect, useState } from "react";
import SubscriptionOption from "../components/SubscriptionOption";

import { api } from "../api";
import "../styles/default.css";

// const TEST_PLANS = [
//   {
//     subscription_plan_id: 10,
//     name: "Free",
//     max_active_buildings: 1,
//     max_timers: 0,
//     max_alerts: 0,
//     stripe_product_id: null,
//     free_trial_days: 3,
//     prices: [{ price: "£10", tenure: "per month", nickname: null, id: null }],
//   },
//   {
//     subscription_plan_id: 20,
//     name: "Basic",
//     max_active_buildings: 5,
//     max_timers: 5,
//     max_alerts: 5,
//     stripe_product_id: null,
//     free_trial_days: 0,
//     prices: [{ price: "free", tenure: null, nickname: null, id: null }],
//   },
//   {
//     subscription_plan_id: 30,
//     name: "Premium",
//     max_active_buildings: 10,
//     max_timers: null,
//     max_alerts: null,
//     stripe_product_id: null,
//     free_trial_days: 0,
//     prices: [
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//       { price: "£10", tenure: "per month", nickname: null, id: null },
//     ],
//   },
// ];

// const TEST_USER = {
//   email: "joe@bloggs.com",
//   first_name: "Joe",
//   last_name: "Bloggs",
//   roles: [],
//   subscription_plan_name: "Free",
//   subscription_plan_id: 20,
//   subscription_status: "active",
//   subscription_current_period_end: null,
//   has_stripe: true,
//   max_active_buildings: 1,
//   max_alerts: 0,
//   max_timers: 0,
// };

const Subscription = () => {
  const [plans, setPlans] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUser();
    getPlans();
  }, []);

  const getUser = () => {
    api("/user").then((res) => {
      if (res) {
        setUser(res);
      }
    });
  };

  const getPlans = () => {
    api("/payments/plans").then((res) => {
      if (res) {
        setPlans(res);
      }
    });
  };

  return (
    <div className="app_content">
      <div className="_page">
        <h2 className="content-title">Pricing</h2>
        <p className="content-desc">
          Manage your subscription here. for the Starter plan you can cancel
          your subscription within 14 days at zero cost. For Enterprise
          customers please contact us directly.
        </p>
        {plans && user && (
          <>
            <ul className="pricing-table col-4">
              {plans && user ? (
                plans.map((plan) => (
                  <li
                    data-animation="true"
                    data-animation-type="fadeInUp"
                    key={plan.subscription_plan_id}
                  >
                    <SubscriptionOption
                      {...plan}
                      hasStripe={user.has_stripe}
                      isCurrentPlan={
                        // Only hide the "Manage Subscription" button if their subscription is active, not just trialing etc
                        user.subscription_plan_id == plan.subscription_plan_id && user.subscription_status == "active"
                      }
                    />
                  </li>
                ))
              ) : (
                <li>Unable to load</li>
              )}
            </ul>
            <h2 className="content-title">Current Subscription</h2>
            <div className="content-desc">
              {user.subscription_plan_name && user.subscription_status != "canceled" ? (
                <p>
                Your current subscription is the{" "}
                <span className="shadow">{user.subscription_plan_name}</span>{" "}
                plan.  Your subscription is {user.subscription_status.replace("_", " ")}.
              </p>
            ) : (
                <p>You currently have no active subscription.</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Subscription;
