import React, { createRef } from "react";
import { isEqual } from "lodash";
import { api } from "../../api";

const guid = () => {
  // Return a random identifier in UUID format
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};


function diff(old_data, new_data, fields) {
  let changes = { added: {}, updated: {} };

  for (const address in new_data) {
    if (old_data[address] === undefined) {
      changes.added[address] = new_data[address];
    } else {
      let hasChanges = false;
      let update = {};

      for (const field of fields) {
        if (field === 'device') {
          const { coms, ...newDeviceData } = new_data[address];
          const oldDeviceData = old_data[address][field] ? { ...old_data[address][field] } : {};
          delete oldDeviceData.coms;

          if (!isEqual(newDeviceData, oldDeviceData)) {
            update[field] = newDeviceData;
            hasChanges = true;
          }
        } else {
          if (
            new_data[address][field] !== undefined &&
            (old_data[address][field] === undefined || !isEqual(new_data[address][field], old_data[address][field]))
          ) {
            update[field] = new_data[address][field];
            hasChanges = true;
          }
        }
      }

      if (hasChanges) {
        changes.updated[address] = update;
      }
    }
  }
  return changes;
}



class FileImportStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "success",
      statusMessage: undefined,
      processing: false,
      submitButtonDisabled: false,
      buildingChanges: undefined,
      changeCounts: undefined,
    };
  }

  applyChanges() {}

  uploadFile() {
    let files = this.wrapper.current.querySelector("input[type='file']").files;
    if (files && files[0]) {
      let file = files[0];

      var data = new FormData();
      data.append("file", file);

      // Create a unique ID for this file.
      this.buildingUploadId = guid();

      window.$.ajax({
        url:
          window.location.origin +
          "/api" +
          "/building/etsimport/" +
          this.buildingUploadId,
        type: "POST",
        headers: { "x-access-token": window.accessToken },
        data: data,
        processData: false,
        contentType: false,
        success: function (/*data*/) {
          //console.log("UPLOAD => ", data);
        },
      });

      this.setState({
        statusMessage: "Uploading...",
        status: "warning",
        processing: true,
      });
      this.fileStatusTimer = setInterval(() => {
        api("/building/etsimport/" + this.buildingUploadId)
          .then((res) => {
            if (this.closed === true) return;
            if (res.status) {
              this.setState({
                statusMessage: res.status.replace(".xml", ""),
                status: "warning",
                processing: true,
              });

              if (res.status === "error") {
                clearInterval(this.fileStatusTimer);
                this.setState({
                  statusMessage: res.document || "Error processing file",
                  status: "error",
                  processing: false,
                });
              }

              if (res.status === "complete") {
                clearInterval(this.fileStatusTimer);
                this.setState({
                  statusMessage: "Comparing to existing data...",
                  status: "warning",
                  processing: true,
                });

                // load existing Groups / Devices / BuildingParts for comparison
                Promise.all([
                  api("/building/group/" + this.props.building._id),
                  api("/building/device/" + this.props.building._id),
                  api("/building/part/" + this.props.building._id),
                ])
                  .then((existing_data) => {
                    if (this.closed === true) return;

                    // The changes object is what we post to the /update API
                    let changes = {
                      groups: diff(
                        existing_data[0].groups,
                        res.document.groups,
                        ["id", "name", "alias", "dpt", "isRange"]
                      ),
                      devices: diff(
                        existing_data[1].devices,
                        res.document.devices,
                        ["id","address" ,"name", "coms","ordernumber","device"]
                      ),
                    };

                    // changeCounts is just to populate the table on this web page
                    let changeCounts = []; // array of objects like {section: "groups", "kind": "updated", count: 42}
                    // Only includes non-zero counts.
                    for (const section of ["devices", "groups"]) {
                      for (const kind of ["added", "updated"]) {
                        if (Object.keys(changes[section][kind]).length > 0) {
                          changeCounts.push({
                            section: section,
                            kind: kind,
                            count: Object.keys(changes[section][kind]).length,
                          });
                        }
                      }
                    }

                    if (
                      !isEqual(
                        res.document.buildingParts,
                        existing_data[2].buildingParts
                      )
                    ) {
                      // We just overwrite the whole object
                      changes.building_parts = res.document.buildingParts;
                      changeCounts.push({
                        section: "building parts",
                        kind: "updated",
                        count: "", // a bit of a hack, we don't know how many changes but this will display something sensible
                      });
                    }

                    if (changeCounts.length === 0) {
                      this.setState({
                        statusMessage:
                          "The uploaded file is the same as the current configuration",
                        status: "success",
                        processing: false,
                        changeCounts: undefined,
                        buildingChanges: undefined,
                      });
                    } else {
                      this.setState({
                        statusMessage: undefined,
                        status: "success",
                        processing: false,
                        changeCounts: changeCounts,
                        buildingChanges: changes,
                      });
                    }
                  })
                  .catch((err) => {
                    this.setState({
                      statusMessage: "Error loading existing data: " + err,
                      status: "error",
                      processing: false,
                    });
                  });
              }
            }
          })
          .catch((err) => {
            clearInterval(this.fileStatusTimer);
            this.setState({
              statusMessage: "Error loading progress: " + err,
              status: "error",
              processing: false,
            });
          });
      }, 800);
    }
  }

  componentWillUnmount() {
    this.closed = true;
    if (this.fileStatusTimer) {
      clearInterval(this.fileStatusTimer);
    }
  }

  wrapper = createRef();

  render() {
    return (
      <div ref={this.wrapper}>
        <div className="">
          {this.state.changeCounts === undefined ? (
            <form className="ui form uploader" encType="multipart/form-data">
              <input
                type="file"
                name="file"
                className="upload-file"
                accept=".knxproj"
              />
              <input
                className="ui primary button upload-button"
                type="button"
                value="Upload"
                disabled={this.state.processing}
                onClick={(e) => {
                  this.uploadFile(e);
                }}
              />
            </form>
          ) : undefined}
          {this.state.statusMessage ? (
            <div className={`ui ${this.state.status} message`}>
              {this.state.processing ? (
                <div className="ui active small inline loader" />
              ) : undefined}{" "}
              {this.state.statusMessage}
            </div>
          ) : undefined}
          {Array.isArray(this.state.changeCounts) &&
          this.state.changeCounts.length > 0 ? (
            <div className="ui clearing segment" style={{ maxWidth: 500 }}>
              <table className="ui green table">
                <tbody>
                  {this.state.changeCounts.map((change) => {
                    return (
                      <tr key={change.kind}>
                        <td>{change.section}</td>
                        <td>
                          {change.count}{" "}
                          <span className="label">{change.kind}</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <button
                className="ui right floated positive button"
                disabled={this.state.submitButtonDisabled}
                onClick={() => {
                  this.setState({
                    submitButtonDisabled: true,
                    statusMessage: "Saving...",
                    status: "warning",
                    processing: true,
                  });
                  // save changes
                  api("/building/update/" + this.props.building._id, {
                    method: "POST",
                    body: JSON.stringify(this.state.buildingChanges),
                  })
                    .then((/*res*/) => {
                      this.setState({
                        buildingChanges: undefined,
                        changeCounts: undefined,
                        submitButtonDisabled: false,
                        statusMessage: "All changes saved",
                        status: "success",
                        processing: false,
                      });
                    })
                    .catch((err) => {
                      this.setState({
                        submitButtonDisabled: false,
                        statusMessage: "Error saving: " + err,
                        status: "error",
                        processing: false,
                      });
                    });
                }}
              >
                Save Changes
              </button>
              <button
                className="ui right floated negative button"
                disabled={this.state.submitButtonDisabled}
                onClick={() => {
                  this.setState({
                    buildingChanges: undefined,
                    changeCounts: undefined,
                    statusMessage: undefined,
                    status: "success",
                    processing: false,
                  });
                }}
              >
                Discard Changes
              </button>
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}

export default FileImportStep;
