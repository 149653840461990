import React, { useEffect, useState } from "react";

import { Icon, Progress } from "semantic-ui-react";

const DPTPercentage = ({ value, onSend }) => {
  const [percentage, setPercentage] = useState(value);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loaded ? send(percentage) : setLoaded(true);
  }, [percentage]);

  const send = (newPercentage) => {
    if (onSend) {
      var newValue = Math.floor(newPercentage);
      setPercentage(newValue);
      onSend(newValue);
    }
  };

  return (
    <Progress
      percent={percentage}
      label={
        <div className="label">
          <div className="ui mini icon buttons">
            <button
              className="ui button"
              onClick={() => {
                setPercentage(0);
              }}
            >
              0%
            </button>
            <button
              className="ui button"
              onClick={() => {
                if (percentage >= 20) {
                  setPercentage(+percentage - 20);
                }
              }}
            >
              <Icon name="minus" />
            </button>
            <button
              className="ui button"
              onClick={() => {
                if (percentage <= 80) {
                  setPercentage(+percentage + 20);
                }
              }}
            >
              <Icon name="plus" />
            </button>
            <button
              className="ui button"
              onClick={() => {
                setPercentage(100);
              }}
            >
              100%
            </button>
          </div>
        </div>
      }
      progress
      success
    />
  );
};

export default DPTPercentage;
