import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Icon } from "semantic-ui-react";

import { api } from "../api";
import "../styles/login.css";

const PasswordReset = () => {
  const [user, setUser] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetPassword = (e) => {
    e.preventDefault();
    setIsLoading(true);

    api("/authenticate/reset", {
      method: "post",
      body: JSON.stringify({ user: user }),
    })
      .then((res) => {
        if (res.success === true) {
          let error = new Error(res.error);
          error.message = "A password reset email has been sent";
          throw error;
        } else {
          let error = new Error(res.error);
          error.message = "Invalid Credential";
          throw error;
        }
      })
      .catch((ex) => {
        setError(ex.message);
        setIsLoading(false);
      });
  };

  return (
    <div className="imagy">
      <div id="header">
        <h1>
          <a href="/">
            KNX<span style={{ color: "black" }}>Monitor</span>
          </a>
        </h1>
      </div>
      <div className="container">
        <form id="RegForm" className="ui form segment">
          <h2 className="ui center aligned icon header">
            <Icon name="key" />
            Reset Password
          </h2>
          <div className="field">
            <label>
              Email
              <input
                type="text"
                value={user}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                placeholder="Username"
              />
            </label>
          </div>
          <div className="ui center aligned header">
            <button
              className={`ui primary button ${
                isLoading ? "loading disabled" : ""
              }`}
              style={{ minWidth: "10em" }}
              type="submit"
              onClick={(e) => resetPassword(e)}
            >
              Reset Password
            </button>
            {error.length > 0 ? (
              <div className="ui left pointing red basic label">{error}</div>
            ) : undefined}
          </div>
        </form>
        <div className="ui center aligned header rows">
          <small>
            Remembered your password? <Link to="/">Log in</Link>.
          </small>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
